
import MenuNav from './componets/menu-navegation'

import 'bootstrap/dist/css/bootstrap.css';
import Navbar from './componets/nav-bar'

function header() {
	return (

		<header>

			<div className="align-items-center">
				<nav className="navbar-expand-lg navbar-light navbar-style">
					<div className="container-fluid">
						<div id="navbarScroll" className="">
							<div className="me-auto ml-5 my-2 my-lg-0 navbar-nav-scroll d-flex justify-content-center">
								<Navbar />
							</div>

						</div>
					</div>
				</nav>
			</div>

		</header>
	);
}
export default header;