import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats'
import ConcatLanding from '../componets/contact-landing.js';
import AboutUs from '../componets/sobreNosotros/AboutUs.js';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import FormContact from '../componets/form-concat.js';


export const SobreNosotrosPage = () => {

	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const location = useLocation();

	useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);

		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	}, [location.pathname]);

	return (
		<div>
			<Header />
			<FloatingButton />
			<WhatsAppButton />
			<AboutUs />

			{/* <div className='container text-center'>
				<h1 id='contact' className='text-center ralewayBold pt-5'>Regístrate <span className='verde-primario-texto'>ahora</span></h1>
				<p className='fs-5'>En <a className=' green-bold-link' href='/'><b>MoneyGiver</b></a> estamos interesados en impulsar el crecimiento<br/>
					de las empresas y colaborar con sus empleados para gestionar sus finanzas personales.</p>
			</div>

			<div className='container p-5 mb-3'>
				<FormContact />
			</div> */}


			<FooterLanding />
		</div>
	);
}