import React from 'react';
import { Button } from 'react-bootstrap';
import 'bootstrap-icons/font/bootstrap-icons.css';

const BackButton = ({ targetPage }) => {

    const handleBack = (e) => {
        e.preventDefault();
        window.location.href = `${targetPage}`;
    };

    return (
        <Button variant="outline-secondary" onClick={handleBack}>
            <i className="bi bi-arrow-left"></i> Regresar
        </Button>
    );
};

export default BackButton;
