import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import splash from '../../assets/as2as5a5ccb5cb5cb5551899878cb8c8b74651bc.jpg';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import '../../App.css';

function ArrendamientoMain() {

	const greenColor = {
		fontFamily: 'RalewayBold',
		fontWeight: 700,
		fontSize: 40,
		lineHeight: '46px',
		color: "#32ac61",
	};

	const textStyles = {
		fontFamily: 'RalewayRegular',
		fontWeight: 400,
		fontSize: "16px",
		whiteSpace: "pre-wrap",
		textAlign: 'justify',
	};

	return (
		<Container>
			<Row >
				<Col md>
					<h1><strong>Arrendamiento</strong></h1>
					<h1><strong><span style={greenColor}>MoneyGiver</span></strong></h1>
					<br />
					<span style={textStyles}>Contrato por tiempo indefinido en donde el trabajador podrá adquirir un vehículo acorde con sus necesidades, esto se realiza mediante un pago fijo mensual donde, una vez cumplido el plazo acordado, el trabajador podrá adquirir el vehículo o contratar otro</span>
					<br />
					<br />
					<ScrollLink className="button-mg-small ralewayBold text-decoration-none text-nowrap"
						to="contact"
						smooth={true}
						duration={800}  // Ajusta la duración según tus necesidades (en milisegundos)
					>Únete ahora</ScrollLink>
				</Col>
				<Col md>
					<Image className='p-3' src={splash} fluid />
				</Col>
			</Row>
		</Container>
	);
}

export default ArrendamientoMain;