import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Unsubscribe } from '../componets/unsubscribe/unsubscribe';

export const UnsubscribePage = () => {


	const formatPageName = (pageName) => {
		return pageName
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const location = useLocation();

	useEffect(() => {

		document.title = `Desuscribirse`;
	}, [location.pathname]);


	return (
		<div id="home" className='banner-building-mg' style={{height:"100vh"}}>
			<Unsubscribe />
		</div>
	)
}