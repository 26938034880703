import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import splash from "../../assets/devices/11- IPHONE WEB3.png";
import aboutUsImage from "../../assets/people/16. Sobre nosotros - suenios.png";
import imageApp from "../../assets/group-33549.png";
import iconTexts from "../../assets/icon-texts.svg";

import { Col, Row, Container } from "react-bootstrap";

function AboutUs() {
  const FontSizeSmall = {
    fontSize: "12px",
  };

  return (
    <div id="home" className="mt-5 pt-3">
      <section className="mt-5 d-flex flex-lg-column flex-md-row align-items-center ">
        <div className=" mx-5  row row-cols-lg-2 row-cols-sm-1 row-cols-md-1 ">
          <div className="d-flex px-5 flex-column justify-content-center ">
            <div className="container  text-center">
              <h2 className="ralewayBold verde-primario-texto">
                MoneyGiver, la mejor manera de remunerar a tu equipo
              </h2>
              <br />
              <p className="ralewayRegular">
                Somos expertos en servicios financieros con herramientas
                innovadoras para empresas de cualquier tamaño. Nuestro enfoque
                original busca brindar soluciones eficaces no solo al negocio,
                sino también a cada colaborador en temas de gestión personal de
                nómina, crédito, préstamos inmediatos y arrendamiento de autos.
              </p>
            </div>
          </div>
          <img
            style={{ maxWidth: "100vw", margin: "auto" }}
            className="d-sm-block d-md-none"
            src={splash}
            alt="img"
          ></img>
          <img
            style={{ maxWidth: "25vw", margin: "auto" }}
            className="d-none d-md-block"
            src={splash}
            alt="img"
          ></img>
        </div>
      </section>
      <section className="banner-circules-mg">
        <Container className=" text-center ">
          <Row className="align-items-center">
            <Col md={6}>
              <img
                style={{ maxWidth: "20vw", margin: "auto" }}
                className="d-none d-md-block"
                src={aboutUsImage}
                alt="img"
              ></img>

              <img
                style={{ maxWidth: "50vw", margin: "auto" }}
                className="d-sm-block d-md-none"
                src={aboutUsImage}
                alt="img"
              ></img>
            </Col>
            <Col md={6}>
              <h2 className="ralewayBold fs-2 ">
                MoneyGiver tecnología financiera:
              </h2>
              <p style={{ fontSize: "20px" }}>
                Al gestionar tu nómina a través de MoneyGiver, accedes a
                beneficios pensados para facilitar tu experiencia financiera
                desde un enfoque digital. La comodidad y rapidez son nuestra
                promesa para que alcances tus metas y objetivos sin tanto
                trámite.
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <Container>
        <div class="d-flex"></div>
      </Container>
      <section className="azul-primario-fondo mb-5 d-flex flex-column align-items-center justify-content-center">
        <div className="container text-center p-5">
          <h2 className="ralewayBold text-white fs-2">
            Tus finanzas ahora son cosa fácil con MoneyGiver
          </h2>
          <Row className="d-md-flex flex-lg-row flex-md-column justify-content-center align-items-center p-2">
            <Col md={12} className=" text-center">
              <img src={iconTexts} alt="img" />
              <h5 className="ralewayBold verde-primario-texto">
                Misión <br />
              </h5>
              <p className="ralewayRegular text-white">
                Ofrecer soluciones financieras eficaces diseñadas para las
                empresas y sus colaboradores, brindando herramientas de gestión
                financiera diseñadas específicamente para cubrir sus necesidades
                y así facilitar el acceso a servicios a la medida en áreas clave
                como crédito, inversión, arrendamiento y pagos. De esta forma
                contribuimos en su bienestar financiero personal y el de la
                empresa.
              </p>
            </Col>

            <Col className=" text-center ">
              <img src={iconTexts} alt="img" />
              <h5 className="ralewayBold verde-primario-texto">Visión</h5>
              <p className="ralewayRegular text-white">
                Mantener un compromiso constante con la innovación para crear
                productos y experiencias que se adapten con flexibilidad tanto
                al sistema de consumidores individuales como al de empresas.
                Este enfoque centrado en el cliente nos impulsa a desarrollar
                soluciones versátiles que respondan a las necesidades únicas de
                cada persona.
              </p>
            </Col>
          </Row>
        </div>
      </section>

      {/* <section className="py-5 d-flex flex-column align-items-center justify-content-center banner-circules-mg">
        <div className="container text-center p-5 m-5">
          <h2 className="ralewayBold fs-2">
            Anticipo de <span className="verde-primario-texto">nómina</span>
          </h2>
          <br />
          <p className="ralewayRegular">
            Es un anticipo de salario que se otorga a personas físicas que
            reciben su nómina en la wallet de{" "}
            <a className="ralewayBold green-bold-link" href="/">
              MoneyGiver
            </a>
            , <br></br>te ayuda a financiar cualquier imprevisto, oportunidad o
            emergencia.
          </p>
        </div>
      </section>
      <section className="m-5 p-auto d-flex flex-column align-items-center justify-content-center">
        <div className="container text-center">
          <h2 className="ralewayBold fs-1">
            <span className="verde-primario-texto">Beneficios</span> MoneyGiver{" "}
          </h2>
          <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3">
            <div className="col">
              <br className="d-md-none"></br>
              <div>
                <img
                  src={iconTexts}
                  alt="img1"
                  className="d-none d-md-inline"
                />
              </div>
              <div>
                <p className="ralewayBold ">
                  Agiliza los trámites <br></br> de nómina
                </p>
              </div>
            </div>
            <div className="col pb-3">
              <div>
                <img
                  src={iconTexts}
                  alt="img2"
                  className="d-none d-md-inline"
                />
              </div>
              <div>
                <p className="ralewayBold">
                  Línea de crédito para <br></br> cumplir tus compromisos{" "}
                  <br></br>
                  <span className="ralewayRegular">(Próximamente)</span>
                </p>
              </div>
            </div>
            <div className="col">
              <div>
                <img
                  src={iconTexts}
                  alt="img3"
                  className="d-none d-md-inline"
                />
              </div>
              <div>
                <p className="ralewayBold ">
                  Tu dinero siempre <br></br> seguro y disponible
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
    </div>
  );
}

export default AboutUs;
