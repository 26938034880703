import iconTexts from "../../assets/icon-texts.svg";
import Laptop from "../../assets/devices/9- LAP WEB.png";
import React, { useState, useEffect } from "react";
import "../../styles/nominaSection3.css";

import { Row, Col, Container, Stack } from "react-bootstrap";

export const Section3 = () => {
  const [isVisible, setIsVisible] = useState(false);

  const [maxWidth, setMaxWidth] = useState("25vw");

  const [verticalAlign, setVerticalAlign] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 900) {
        setMaxWidth("80vw");
        setVerticalAlign(true);
      } else {
        setMaxWidth("25vw");
        setVerticalAlign(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Para ajustar el tamaño al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="">
      <div className="container text-center">
        <h1 className="ralewayBold py-5 verde-primario-texto">
          Recibe tu nómina en MoneyGiver
        </h1>

        <Container>
          <Stack direction={verticalAlign ? "vertical" : "horizontal"} gap={3}>
            <Stack gap={3}>
              <div className={verticalAlign ? "text-center" : "text-end"}>
                <div
                  className={
                    verticalAlign
                      ? "mb-4 d-flex flex-column align-items-center"
                      : "mb-4 d-flex flex-column align-items-end"
                  }
                >
                  <img
                    src={iconTexts}
                    alt="..."
                    className="img-fluid pb-3 d-none d-md-block"
                  />
                  <div className="">
                    <div className="d-flex align-items-center">
                      <img
                        src={iconTexts}
                        alt="..."
                        className="img-fluid pb-3 d-md-none"
                      />
                    </div>
                    <h3 className="text-wrap ralewayBold">
                      Administración de empleados
                    </h3>

                    <p className="text-wrap">
                      Nuestra plataforma web es tan amigable que te permitirá
                      dar de alta o baja a tus colaboradores en cualquier
                      momento.
                    </p>
                  </div>
                </div>
                <div className={verticalAlign ? "text-center" : "text-end"}>
                  <div
                    className={
                      verticalAlign
                        ? "mb-4 d-flex flex-column  align-items-center"
                        : "mb-4 d-flex flex-column  align-items-end"
                    }
                  >
                    <img
                      src={iconTexts}
                      alt="..."
                      className="img-fluid pb-3 d-none d-md-block"
                    />
                    <div className="ml-3">
                      <img
                        src={iconTexts}
                        alt="..."
                        className="img-fluid pb-3 d-md-none"
                      />
                      <h3 className="text-wrap ralewayBold">
                        Gestión de la dispersión de nómina
                      </h3>
                      <p className="text-wrap ">
                        Gestiona los pagos que enviarás a cada colaborador de tu
                        empresa de manera rápida y sencilla.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Stack>
            <Stack
              id="laptop-section"
              className="align-items-center justify-content-center"
            >
              <img
                src={Laptop}
                alt="LAPTOP MG"
                className={`img-fluid ${isVisible ? "visible" : ""}`}
              />
            </Stack>
            <Stack gap={3}>
              <div className={verticalAlign ? "text-center" : "text-start"}>
                <div
                  className={
                    verticalAlign
                      ? "mb-4 d-flex flex-column  align-items-center"
                      : "mb-4 d-flex flex-column  align-items-start"
                  }
                >
                  <img
                    src={iconTexts}
                    alt="..."
                    className="img-fluid pb-3 d-none d-md-block"
                  />
                  <div className="ml-3">
                    <div className="d-flex align-items-center">
                      <img
                        src={iconTexts}
                        alt="..."
                        className="img-fluid pb-3 d-md-none"
                      />
                    </div>
                    <h3 className="text-wrap ralewayBold">
                      Creación masiva de empleados
                    </h3>
                    <p className="text-wrap ">
                      Da de alta a todos tus colaboradores mediante un archivo
                      de manera inmediata.
                    </p>
                  </div>
                </div>
              </div>
              <div className="text-start">
                <div
                  className={
                    verticalAlign
                      ? "mb-4 d-flex flex-column  align-items-center"
                      : "mb-4 d-flex flex-column  align-items-start"
                  }
                >
                  <img
                    src={iconTexts}
                    alt="..."
                    className="img-fluid pb-3 d-none d-md-block"
                  />
                  <div className="ml-3 ">
                    <img
                      src={iconTexts}
                      alt="..."
                      className="img-fluid pb-3 d-md-none"
                    />
                  </div>
                  <h3 className="text-wrap ralewayBold">
                    Monitoreo de transacciones
                  </h3>
                  <p className="text-wrap">
                    Revisa en tiempo real si la dispersión de nómina se ha
                    realizado con éxito.
                  </p>
                </div>
              </div>
            </Stack>
          </Stack>
        </Container>

        <div className="container text-center">
          <div className="d-flex flex-lg-column flex-md-row align-items-center"></div>
        </div>
      </div>
    </section>
  );
};
