
import { ApiGetDatosEmpresaxFolio ,ApiGetAperturaCuentaContinue ,ApiGetDocumentosAperturaCuentaRejected, ApiGetDocumentosAperturaCuentaMissing, ApiGetDocumentosAperturaCuenta, ApiSendEmailForANewMerchantAccount, ApiUpdateDocument } from "./ClientsAccounts"

export const ServiceGetDocumentsApertureAccount = async (isDev = false) => {
  const response = await ApiGetDocumentosAperturaCuenta(isDev)
  try {
    if (response.status === 200) {
      return response.data.documentos.map((documento) => documento)
    }
  } catch (error) {
    console.log(error)
    return response

  }
}


export const ServiceSendEmailNewMerchantAccount = async (idAperturaCuenta, claveGrupoEmpresarial, isDev = false) => {

  const request = {
    idAperturaCuenta: idAperturaCuenta,
    claveGrupoEmpresarial: claveGrupoEmpresarial
  }
  
 const response = await ApiSendEmailForANewMerchantAccount(request, isDev)
  try {
    

    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
    return response
  }

}

export const ServiceGetDocumentsRejected = async (idAperturaCuenta, isDev = false) => {
  const response = await ApiGetDocumentosAperturaCuentaRejected(isDev, { idAperturaCuenta})
  try {
    
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
    return response
  }
}

export const ServiceGetDocumentsMissing = async (idAperturaCuenta) => {
  const response = await ApiGetDocumentosAperturaCuentaMissing({ idAperturaCuenta})
  try {
    
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
    return response
  }
}


export const ServiceUpdateDocument = async (  idAperturaCuenta = '', isDev = false, documentToUpdate = {
  documentos: {
    idDocumento: 0,
    nombreDocumento: '',
    docBase64: '',
  }
}) => {
  const response = await ApiUpdateDocument( idAperturaCuenta, isDev, documentToUpdate)
  try {
    
    if (response.status === 200) {
      return response.data
    }
  } catch (error) {
    console.log(error)
    throw new Error("Couldn't update document: ", error);
  }
}

export const ServiceGetAperturaCuentaContinue = async (idAperturaCuenta) => {
  const response = await ApiGetAperturaCuentaContinue({ idAperturaCuenta})
  try{
    if(response.status === 200){
      return response.data
    }
  }catch(error){
    console.log(error)
    return response
  }
}


export const ServiceGetDataMerchantById = async (idAperturaCuenta) => {
  try {
    const response = await ApiGetDatosEmpresaxFolio({ idAperturaCuenta });

    if (response.status === 200) {
      const datos = response.data.datos;
      if (Array.isArray(datos) && datos.length > 0) {
        return datos[0];
      } else {
        throw new Error("No data found");
      }
    } else {
      throw new Error("Error fetching data");
    }
  } catch (error) {
    console.error(error);
    return error;
  }
};
