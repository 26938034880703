import React, { useEffect, useState } from 'react';
import { Col, Image } from 'react-bootstrap';
import PersonalImage from "../../assets/people/4. Personales.jpg";
import iconTexts from '../../assets/icon-texts.svg';

export const PersonalBenefits = () => {
    const justify = {
        textAlign: 'justify',
    };

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Col className='mt-5 d-lg-flex flex-lg-row flex-sm-column align-items-center justify-content-between' xs={12} md={12} lg={12}>
            <Image style={{ maxWidth: "40vw" }} className={`img-fluid ${windowWidth < 400 ? 'd-inline' : 'd-none  '}`} src={PersonalImage} alt='...'></Image>
            <div className='mx-3'>
                <h4 className="card-title text-start  ralewayBold py-3">Beneficios personales</h4>
                <p className="card-text ralewayLight" style={justify}>Los empleados que reciben su pago de nómina a través de MoneyGiver reciben múltiples beneficios que los apoyan en la gestión y control de sus recursos financieros, bonificaciones y los asiste en casos de imprevistos. </p>
                <div className='d-flex flex-column align-items-start'>
                    {/* <img style={{ verticalAlign: "start" }} src={iconTexts} alt='...' className='img-fluid'></img> */}
                    <h4 className='ralewayBold text-start'>Soporte automatizado los 24/7 días del año</h4>
                    <p className='ralewayLight pt-2' style={justify}>Ten la tranquilidad que ante cualquier duda o problema estaremos ahí para apoyarte, con un equipo altamente calificado para dar la mejor experiencia de usuario.</p>
                </div>
            </div>
            <Image style={{ maxWidth: "40vw" }} className={`img-fluid ${windowWidth < 400 ? 'd-none' : 'd-inline'}`} src={PersonalImage} alt='...'></Image>
        </Col>
    )
}
