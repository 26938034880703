import React, { useState, useEffect } from 'react';
import { Navbar as BootstrapNavbar, Nav, Offcanvas, Button, Dropdown } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import '../styles/navbar.css';
import logo from '../assets/logomoneygiverebanking_white.png';
import moneyGiverEBankingBlack from '../assets/logo_moneygiver_ebanking.png';

const Navegacion = () => {
    const [isNavbarSticky, setIsNavbarSticky] = useState(false);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [activeDropdown, setActiveDropdown] = useState(false);
    const location = useLocation();

    const handleScroll = () => {
        const scrollPosition = window.scrollY;
        setIsNavbarSticky(scrollPosition > 100);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleOffcanvasToggle = () => {
        setShowOffcanvas(!showOffcanvas);
    };

    const handleDropdownToggle = (isOpen) => {
        setActiveDropdown(isOpen);
    };

    const isActive = (pathname) => location.pathname === pathname ? 'active-link' : '';

    return (
        <>
            <BootstrapNavbar
                className={`navbar ${isNavbarSticky ? 'sticky' : ''} px-5 d-flex justify-content-center flex-grow-1`}
                expand="lg"
                fixed="top"
                style={{ backgroundColor: '#1C1E3C' }}
            >
                <BootstrapNavbar.Brand href="/">
                    <img className="logo-img" src={logo} alt="..." />
                </BootstrapNavbar.Brand>
                <Button variant="link" onClick={handleOffcanvasToggle} className="navbar-toggler">
                    <span style={{ color: 'white', backgroundColor: 'white' }} className="navbar-toggler-icon"></span>
                </Button>
                <BootstrapNavbar.Collapse id="basic-navbar-nav" className="justify-content-end">
                    <Nav className="ml-auto">
                        <Link to="/" className={`pe-3 ralewayRegular nav-mg-link ${isActive('/')}`}>Inicio</Link>
                        <Link to="/nomina" className={`px-3 ralewayRegular nav-mg-link ${isActive('/nomina')}`}>Nómina</Link>
                        <Dropdown onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle
                                style={{ color: activeDropdown || location.pathname.includes('/credito') ? '#32AC61' : 'white', fontWeight: 'lighter' }}
                                variant=""
                                id="dropdown-basic"
                                className="ralewayRegular"
                            >
                                Crédito
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/credito/credito_MG" className={`ralewayRegular ${isActive('/credito/credito_MG')}`}>Crédito MG</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/credito/prestamo_MG" className={`ralewayRegular ${isActive('/credito/prestamo_MG')}`}>Préstamo</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to="/sobre_nosotros" className={`px-3 ralewayRegular nav-mg-link ${isActive('/sobre_nosotros')}`}>Sobre nosotros</Link>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </BootstrapNavbar>

            <Offcanvas show={showOffcanvas} onHide={handleOffcanvasToggle} placement="end">
                <Offcanvas.Header closeButton>
                    <img className="logo-img" src={moneyGiverEBankingBlack} alt="..." />
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <Nav className="flex-column ralewayBold">
                        <Link to="/" className={`verde-primario-text item-nav ${isActive('/')}`} onClick={handleOffcanvasToggle}>
                            Inicio
                        </Link>
                        <Link to="/nomina" className={`verde-primario-text item-nav ${isActive('/nomina')}`} onClick={handleOffcanvasToggle}>
                            Nómina
                        </Link>
                        <Dropdown onToggle={handleDropdownToggle}>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="ralewayRegular" style={{ color: activeDropdown || location.pathname.includes('/credito') ? 'green' : '' }}>
                                Crédito
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item as={Link} to="/credito/credito_MG" className={`ralewayRegular ${isActive('/credito/credito_MG')}`} onClick={handleOffcanvasToggle}>Crédito MG</Dropdown.Item>
                                <Dropdown.Item as={Link} to="/credito/prestamo_MG" className={`ralewayRegular ${isActive('/credito/prestamo_MG')}`} onClick={handleOffcanvasToggle}>Préstamo</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Link to="/sobre_nosotros" className={`verde-primario-text item-nav ${isActive('/sobre_nosotros')}`} onClick={handleOffcanvasToggle}>
                            Sobre nosotros
                        </Link>
                    </Nav>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

export default Navegacion;
