import React, {useEffect, useState} from 'react';
import CardMG from '../../assets/cards/10-TARJETAS CIRCULO.png';
import { Container, Row, Col } from 'react-bootstrap';

export const Section2Credito = () => {
	const textSize = {
		fontSize: '14px'
	}

	const [maxWidth, setMaxWidth] = useState("25vw");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1000) {
        setMaxWidth("50vw");
      } else {
        setMaxWidth("25vw");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Para ajustar el tamaño al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

	return (
		<section className='banner-circules-mg'>
			<Container className='py-5'>
				<div className='d-md-flex justify-content-between align-items-center my-5 flex-lg-row flex-md-column'>

					<div className='text-start flex-md-grow-1'>
						<div className='my-4'>
							<h5 className='text-wrap ralewayBold'>Préstamos sin aval ni garantías</h5>
							<p className='text-wrap ralewayRegular'>Si tienes alguna emergencia, puedes solicitar un préstamo inmediato.</p>
						</div>

						<div className='my-4'>
							<h5 className='text-wrap ralewayBold'>Préstamos MoneyGiver</h5>
							<p className='text-wrap ralewayRegular'>Si tienes alguna emergencia, puedes solicitar un préstamo inmediato.</p>
						</div>
					</div>

					<div className='flex-md-grow-1'>
						<img style={{maxWidth:maxWidth}} src={CardMG} alt='Tarjetas MG' className='img-fluid' />
					</div>

					<div className='text-start flex-md-grow-1'>
						<div className='my-4'>
							<h5 className='text-wrap ralewayBold'>Control de tus tarjetas</h5>
							<p className='text-wrap ralewayRegular'>Bloquea y desbloquea tu tarjeta fisica o virtual cuando lo requieras.</p>
						</div>

						<div className='my-4'>
							<h5 className='text-wrap ralewayBold'>Seguro médico</h5>
							<p className='text-wrap ralewayRegular'>Para que siempre estés protegido, te ofrecemos diferentes opciones de seguro médico.</p>
						</div>
					</div>

				</div>
			</Container>
		</section>

	);
}