import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import FormularioPreRegistro from '../componets/formularioPreAltaMerchant/formularioMerchant/formularioPreRegistro';
export const PreRegistroPage = () => {


	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const location = useLocation();

	useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);

		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	}, [location.pathname]);


	return (
		<div id="home" style={{overflowX:"hidden"}} className=' container '>
			<FormularioPreRegistro />
		</div>
	)
}