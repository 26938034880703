import { Button, Alert, Spinner } from 'react-bootstrap';

export const AlertaFormulario = ({ idAperturaCuenta, idGrupoEmpresarial, tipo, titulo, mensaje, httpStatusCode }) => {
    return (
        <Alert variant={tipo} style={{ textAlign: 'center' }}>
            <h3 style={{ marginBottom: '1rem' }}>{titulo}</h3>
            <p>{mensaje}</p>



            {httpStatusCode === 100 ? <>
                <p>
                    Su información si se ha enviado, pero no se pudo enviar el correo a MoneyGiver, por favor, contáctesé por medio de WhatsApp.
                </p>
            </> : null}
            {idAperturaCuenta && <>



                <p>Aquí esta su folio de la apertura: <strong>{idAperturaCuenta}</strong></p>
                <p>Y su folio de grupo: <strong>{idGrupoEmpresarial}</strong></p>
                <Button size="sm" onClick={() => {
                    navigator.clipboard.writeText(`Folio de apertura: ${idAperturaCuenta}\nFolio de grupo: ${idGrupoEmpresarial}`);
                }} variant="outline-success" style={{ display: 'block', margin: '0 auto' }}>
                    <i className="bi-copy"></i> Copiar Folios
                </Button>
            </>}
        </Alert>
    )
}