import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Modal,
  Row,
  Image,
  ToggleButton,
  ToggleButtonGroup,
  Spinner,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import EBanking from "../../assets/logo_moneygiver_ebanking.png";
import {
  ApiPostApertureClient,
  ApiPostApertureClientWithOutDocuments,
} from "../../services/clients/ClientsAccounts";
import CustomModal from "../shared/Modal";
import BackButton from "../shared/ReturnButton";
import { useLocation } from "react-router-dom";
import {
  ServiceGetDataMerchantById,
  ServiceGetDocumentsApertureAccount,
  ServiceSendEmailNewMerchantAccount,
} from "../../services/clients/serviceClientsAccounts";
import NotificationToast from "../notificacion";
import usePedirDatosPreAlta from "./pedir-datos-prealta";
import { TengoFolio } from "./tengoFolio";
export const FormularioMerchant = () => {
  const [formData, setFormData] = useState({
    idAperturaCuenta: "",
    nombreEmpresa: "",
    razonSocial: "",
    nombreRL: "",
    primerApRL: "",
    segundoApRL: "",
    correo: "",
    telefono: "",
    rutaDocumentos: "",
    idEstatus: 2,
    grupoEmpresarial: false,
    claveGrupoEmpresarial: "",
    documentos: [],
  });
  const [isDev, setIsDev] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [telefonoError, setTelefonoError] = useState("");
  const [nombreRL, setNombreRL] = useState("");
  const [primerApRL, setPrimerApRL] = useState("");
  const [segundoApRL, setSegundoApRL] = useState("");
  const [nombreEmpresa, setNombreEmpresa] = useState("");
  const [razonSocial, setRazonSocial] = useState("");
  const [fileErrors, setFileErrors] = useState([]);
  const [showIdGrupo, setShowIdGrupo] = useState(false);
  const [rfcError, setRfcError] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalButtonVariant, setModalButtonVariant] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [modalMessageResponse, setModalMessageResponse] = useState("");
  const [modalIdPreRegister, setIdPreRegister] = useState("");
  const [titleToast, setTitleToast] = useState("");
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const [loading, setLoading] = useState(false);
  const [filesForm, setFilesForm] = useState([]);
  const [showA, setShowModalA] = useState(false);
  const [fetchData, setFetchData] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const idAperturaCuenta = searchParams.get("ao");
  const previousPage = "formulario_merchant";
  const { datos, error, loadingPreRegistarData } = usePedirDatosPreAlta(
    fetchData ? idAperturaCuenta : null
  );

  const handleToast = (title, message, type) => {
    setTitleToast(title);
    setMessageToast(message);
    setTypeToast(type);
    setShowModalA(true);
  };

  // Función para mostrar el modal
  const handleShowModal = (
    title,
    body,
    buttonText,
    buttonVariant,
    folioGrupoEmpresarial,
    folioAperturaCuenta
  ) => {
    setModalTitle(title);
    setModalBody(body);
    setModalButtonText(buttonText);
    setModalButtonVariant(buttonVariant);
    setModalMessageResponse(folioGrupoEmpresarial);
    setIdPreRegister(folioAperturaCuenta);
    setShowModal(true);
  };

  const toggleShowA = () => {
    setShowModalA(!showA);
  };

  // Función para ocultar el modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Ejemplo de cómo utilizar el modal de éxito
  const handleSuccessModal = (folioGrupoEmpresarial, folioAperturaCuenta) => {
    handleShowModal(
      "Formulario Enviado",
      "¡Tu formulario ha sido enviado con éxito!",
      "Cerrar",
      "primary",
      folioGrupoEmpresarial,
      folioAperturaCuenta
    );
  };

  // Ejemplo de cómo utilizar el modal de error
  const handleErrorModal = (error = "") => {
    handleShowModal("Error", error, "Cerrar", "danger");
  };

  const handleChange = (e) => {
    const { name, type, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === "telefono") {
      // Validar si está vacío
      const newValue = value.replace(/\D/g, "").slice(0, 10);
      setFormData({ ...formData, [name]: newValue });

      // Validar si está vacío
      if (!newValue.trim()) {
        setTelefonoError("Este campo es requerido.");
      } else if (newValue.length !== 10) {
        setTelefonoError("El teléfono debe contener 10 dígitos numéricos.");
      } else {
        setTelefonoError("");
      }
    } else if (name === "razonSocial") {
      // Validar el formato
      if (!value.trim()) {
        setRazonSocial("Este campo es requerido.");
      } else {
        setRazonSocial("");
      }
    } else if (name === "nombreRL") {
      // Validar el formato
      if (!value.trim()) {
        setNombreRL("Este campo es requerido.");
      } else {
        setNombreRL("");
      }
    } else if (name === "primerApRL") {
      // Validar el formato
      if (!value.trim()) {
        setPrimerApRL("Este campo es requerido.");
      } else {
        setPrimerApRL("");
      }
    } else if (name === "segundoApRL") {
      // Validar el formato
      if (!value.trim()) {
        setSegundoApRL("Este campo es requerido.");
      } else {
        setSegundoApRL("");
      }
    } else if (name === "nombreEmpresa") {
      // Validar el formato
      if (!value.trim()) {
        setNombreEmpresa("Este campo es requerido.");
      } else {
        setNombreEmpresa("");
      }
    } else if (name === "rfcRepresentanteLegal") {
      // Validar el RFC
      const rfcRegex = /^[A-Z&Ña-zñ]{3,4}[0-9]{6}[0-9A-Za-z]{3}$/i;

      if (!value.trim()) {
        setRfcError("Este campo es requerido.");
      } else {
        setRfcError("");
      }

      if (!rfcRegex.test(value.toUpperCase())) {
        setRfcError("RFC inválido. Verifica el formato .");
      } else {
        setRfcError("");
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    if (type === "file") {
      const idDocumento = e.target.closest(".my-3").id;
      const file = e.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      const maxSize = 15 * 1024 * 1024; // 15 MB

      if (file) {
        if (!validTypes.includes(file.type)) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]:
              "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF Y EXCEL.",
          }));
          handleToast(
            "Error en el archivo",
            "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF Y EXCEL.",
            "error"
          );
          e.target.setCustomValidity("Invalid");
          return;
        }
        if (idDocumento === "2" || idDocumento === "1") {
          if (file.size > 25 * 1024 * 1024) {
            setFileErrors((prevErrors) => ({
              ...prevErrors,
              [idDocumento]: "La acta constitutiva no debe superar los 25 MB.",
            }));
            handleToast(
              "Error en la acta constitutiva",
              "El archivo no debe superar los 25 MB.",
              "error"
            );
            e.target.setCustomValidity("Invalid");
            return;
          }
        }

        if (file.size > maxSize) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]: "El archivo no debe superar los 15 MB.",
          }));
          handleToast(
            "Error en el archivo",
            "El archivo no debe superar los 15 MB.",
            "error"
          );
          e.target.setCustomValidity("Invalid");
          return;
        }
        e.target.setCustomValidity("");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];

          // Crear el objeto de documento con el idDocumento obtenido
          const nuevoDocumento = {
            idDocumento,
            nombreDocumento: file.name,
            docBase64: base64String,
          };
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]: "",
          }));
          // Actualizar el estado formData agregando el nuevo documento
          setFormData((prevData) => ({
            ...prevData,
            documentos: [...prevData.documentos, nuevoDocumento],
          }));
          //e.target.setCustomValidity("Valid");
        };
        reader.onerror = (error) => {
          console.error("Error al leer el archivo:", error);
        };
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity()) {
      setLoading(true); // Activar el indicador de carga
      try {
        let response;
        const isDev = isDevEnv()
        if (idAperturaCuenta === "" || !idAperturaCuenta) {
          formData.idAperturaCuenta = "00000000-0000-0000-0000-000000000000";
        } else {
          formData.idAperturaCuenta = idAperturaCuenta;
        }

        if (showForm) {
          response = await ApiPostApertureClient(formData, showIdGrupo, isDev);
        } else {
          response = await ApiPostApertureClientWithOutDocuments(
            formData,
            showIdGrupo,
            isDev
          );
        }
        //console.log(response);
        if (!response.data) {
          //console.log("Error en la petición:", response);
          setShowModal(false);
          handleErrorModal(
            "Hubo un error en el procesamiento de sus datos, vuelva a intentarlo"
          );
        } else if (response.data.httpStatusCode === 100) {
          setShowModal(true);
          handleErrorModal(response.data.message);
        } else {
          setShowModal(true);
          const sendEmail = await ServiceSendEmailNewMerchantAccount(
            response.data.aperturaCuenta.idAperturaCuenta,
            response.data.aperturaCuenta.claveGrupoEmpresarial,
            isDev
          );
          //console.log(sendEmail);
          handleSuccessModal(
            response.data.aperturaCuenta.claveGrupoEmpresarial,
            response.data.aperturaCuenta.idAperturaCuenta
          );
        }
      } catch (error) {
        console.error("Error al hacer la solicitud:", error.response);
        setShowModal(false);
        handleErrorModal(
          "Hubo un error por parte del servidor, por favor verifique su conexión o intente más tarde."
        );
      } finally {
        setLoading(false); // Desactivar el indicador de carga
      }
    } else {
      handleToast(
        "Error en los archivos",
        "Verifique el formato de los archivos,  solo se permiten JPG, JPEG, PNG, PDF Y EXCEL y de 5MB de tamaño.",
        "error"
      );
    }

    setValidated(true);
  };

  const handleShowIdGrupo = (e) => {
    setShowIdGrupo(e.target.value === "true");
    formData.grupoEmpresarial = e.target.value === "true";
  };

  const fetchDocuments = async () => {
    try {
      const isDev = isDevEnv()
      const documentos = await ServiceGetDocumentsApertureAccount(isDev);

      const nuevosArchivos = documentos.map((documento) => ({
        idDocumento: documento.idDocumento,
        nombre: documento.documento,
        tipo: "file",
        required: documento.requerido,
        description: documento.descripcion,
      }));

      setFilesForm(nuevosArchivos);
    } catch (error) {
      setFilesForm([
        {
          idDocumento: 1,
          nombre: "Error en la carga de los documentos",
          tipo: "file",
          required: true,
          description:
            "No se pudo cargar los documentos a enviar, por favor intente despues...",
        },
      ]);
      console.error("Error fetching documents:", error);
      // Puedes agregar más lógica de manejo de errores aquí, como mostrar un mensaje de error en la UI
    }
  };

  const fetchPreRegisterById = async (idAperturaCuenta) => {
    const response = await ServiceGetDataMerchantById(idAperturaCuenta);
    //console.log(response);

    const responseWithDocuments = {
      ...response,
      documentos: [],
    };

    setFormData(responseWithDocuments);
  };

  useEffect(() => {
    if (isDevEnv()) {
      setIsDev(true);
    }

    fetchDocuments();

    if (idAperturaCuenta) {
      setShowForm(true);
      fetchPreRegisterById(idAperturaCuenta);
    }
  }, []);

  const isDevEnv = () =>{
    const currentPath = location.pathname;
    const isDevPath = currentPath.includes("/formulario_merchant");
    return isDevPath;
  }

  return (
    <div className="container  p-5 d-flex flex-column align-items-center justify-content-center ">
      <Image src={EBanking} className="img-fluid pb-3" />

      {idAperturaCuenta ? (
        <div className="d-flex align-items-center justify-content-center">
          <BackButton targetPage={previousPage} />
          <h5 className="text-center ralewayBold ms-2">
            Para continuar con su alta a Money Giver, necesita proporcionar la
            siguiente información:
          </h5>
        </div>
      ) : (
        <TengoFolio></TengoFolio>
      )}

      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className="row">
          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="nombreEmpresa">
              <Form.Label className="ralewayRegular">
                Nombre de la empresa
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="nombreEmpresa"
                value={formData.nombreEmpresa}
                onChange={handleChange}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {nombreEmpresa || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="razonSocial">
              <Form.Label className="ralewayRegular">
                Razón social
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="razonSocial"
                value={formData.razonSocial}
                onChange={handleChange}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {razonSocial || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="nombreRL">
              <Form.Label className="ralewayRegular">
                Nombre del representante legal{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="nombreRL"
                value={formData.nombreRL}
                onChange={handleChange}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {nombreRL || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>
          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="primerApRL">
              <Form.Label className="ralewayRegular">
                Primer apellido del representante legal{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="primerApRL"
                value={formData.primerApRL}
                onChange={handleChange}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {primerApRL || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="segundoApRL">
              <Form.Label className="ralewayRegular">
                Segundo apellido del representante legal{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="segundoApRL"
                value={formData.segundoApRL}
                onChange={handleChange}
                type="text"
                required
              />
              <Form.Control.Feedback type="invalid">
                {segundoApRL || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="correo">
              <Form.Label className="ralewayRegular">
                Correo electrónico quien tendra acceso al panel de dispersión{" "}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="email"
                name="correo"
                value={formData.correo}
                onChange={handleChange}
                required
              />
              <Form.Control.Feedback type="invalid">
                Por favor, ingresa un correo electrónico válido.
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} md={12} xl={12} className="my-3">
            <Form.Group controlId="telefono">
              <Form.Label className="ralewayRegular">
                Teléfono de Contacto <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                name="telefono"
                value={formData.telefono}
                onChange={handleChange}
                pattern="[0-9]{10}"
                type="tel"
                required
              />
              <Form.Control.Feedback type="invalid">
                {telefonoError || "Este campo es requerido."}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          {!idAperturaCuenta && (
            <Col xs={12} md={12} xl={12} className="my-3">
              <Form.Label className="ralewayRegular px-2">
                ¿Usted requiere crear un grupo empresarial? (Si no tiene un
                folio por favor marque Si)
              </Form.Label>
              <Form.Check
                inline
                type="radio"
                label="Sí"
                name="grupoEmpresarial"
                value="true"
                checked={showIdGrupo === true}
                onChange={handleShowIdGrupo}
              />
              <Form.Check
                inline
                type="radio"
                label="No"
                name="grupoEmpresarial"
                value="false"
                checked={showIdGrupo === false}
                onChange={handleShowIdGrupo}
              />

              <Form.Group controlId="claveGrupoEmpresarial">
                <Form.Label className="ralewayRegular">
                  Coloque su clave empresarial si tiene un codigo de grupo.{" "}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  name="claveGrupoEmpresarial"
                  value={formData.claveGrupoEmpresarial}
                  onChange={handleChange}
                  disabled={showIdGrupo}
                  required
                  type="text"
                />
              </Form.Group>
            </Col>
          )}
          {idAperturaCuenta ? (
            <Row>
              {showForm &&
                filesForm.map((campo, index) => (
                  <Col
                    id={campo.idDocumento}
                    key={campo.idDocumento}
                    xs={6}
                    md={6}
                    xl={6}
                    className="my-3"
                  >
                    <Form.Group controlId={`formFile${campo.idDocumento}`}>
                      <Form.Label className="ralewayRegular">
                        {campo.nombre}{" "}
                        {campo.required ? (
                          <span className="text-danger">*</span>
                        ) : null}
                      </Form.Label>
                      {!campo.description ? null : (
                        <>
                          <br /> <Form.Text>{campo.description}</Form.Text>
                        </>
                      )}
                      <Form.Control
                        type="file"
                        onChange={handleChange}
                        required={campo.required ?? false}
                      />
                      <Form.Control.Feedback type="invalid">
                        {fileErrors[campo.idDocumento] ||
                          "Este campo es requerido."}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                ))}
            </Row>
          ) : (
            <Accordion className="my-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header
                  onClick={() => {
                    if (idAperturaCuenta) return;
                    setShowForm(!showForm);
                    if (!showForm)
                      setFormData({ ...formData, ["documentos"]: [] });
                  }}
                >
                  ¿Desea mandar documentación de la empresa?
                </Accordion.Header>
                <Accordion.Body>
                  <Row>
                    {showForm &&
                      filesForm.map((campo, index) => (
                        <Col
                          id={campo.idDocumento}
                          key={campo.idDocumento}
                          xs={6}
                          md={6}
                          xl={6}
                          className="my-3"
                        >
                          <Form.Group
                            controlId={`formFile${campo.idDocumento}`}
                          >
                            <Form.Label className="ralewayRegular">
                              {campo.nombre}{" "}
                              {campo.required ? (
                                <span className="text-danger">*</span>
                              ) : null}
                            </Form.Label>
                            {!campo.description ? null : (
                              <>
                                <br />{" "}
                                <Form.Text>{campo.description}</Form.Text>
                              </>
                            )}
                            <Form.Control
                              type="file"
                              onChange={handleChange}
                              required={campo.required ?? false}
                            />
                            <Form.Control.Feedback type="invalid">
                              {fileErrors[campo.idDocumento] ||
                                "Este campo es requerido."}
                            </Form.Control.Feedback>
                          </Form.Group>
                        </Col>
                      ))}
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}

          <Col md={4}>
            <button
              type="submit"
              disabled={fileErrors.length > 0}
              className="ralewayBold button-mg-blue"
            >
              {loading ? (
                <Spinner animation="border" role="status"></Spinner>
              ) : (
                "Enviar"
              )}
            </button>
          </Col>
        </Row>
      </Form>
      <NotificationToast
        title={titleToast}
        message={messageToast}
        type={typeToast}
        show={showA}
        onClose={toggleShowA}
      ></NotificationToast>
      {/* Modal reutilizable */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={modalTitle}
        body={modalBody}
        buttonText={modalButtonText}
        buttonVariant={modalButtonVariant}
        responseContent={modalMessageResponse}
        folioAperturaCuenta={modalIdPreRegister}
      />
    </div>
  );
};
