import logo from './logo.svg';
import './App.css';
import Header from './header.js'
import 'bootstrap/dist/css/bootstrap.css';
import Main from './Main';
import FooterLanding from './componets/footer-landing';
import FloatingButton from './componets/button-go-up';
import WhatsAppButton from './componets/button-go-whats'
import { Rutas } from './rutas';


const noHorizontal = {
	overflow: 'hidden'
};


//console.log(process.env);

function App() {
	
		return (
		<Rutas style={noHorizontal} />

	);
}

export default App;

