import axios, {AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from '../../helpers/GlobalVariables';


const session ={
    token: "hola"
}

const config:AxiosRequestConfig = {
    headers: { 
        "Authorization": `Bearer ${session.token}`, 
        "Content-Type": "application/json",
    },
};

export const ApiPostMerchantAdd = (merchantToAdd) => {
    
    //console.log(config);
    return axios.post(`${API_BASE_URL}/merchants/add`, merchantToAdd, config)
        .then(response => {
            //console.log(response);
            return response;
        })
        .catch(error => {
            console.error('Error al hacer la solicitud a la API:', error.message);
            throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
        });
}