import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats';
import moneyGiverLogo from '../assets/logo_moneygiver_ebanking.png'
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
export const DatosUNE = () => {

	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
		  .split('_')
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  };

	  const location = useLocation();

	  useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
		
		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);
	
		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	  }, [location.pathname]);

	return (
		<div id="home">
			<Header />
			<FloatingButton />
			<WhatsAppButton />

			<div className=' my-5 py-4 text-center '>
				<img src={moneyGiverLogo} className='img-fluid my-5' alt='...'></img>
				<h3 className="ralewayBold py-5 lh-base negro-primario-texto"><strong>UNIDAD ESPECIALIZADA PARA LA ATENCIÓN DE
					<br />CONSULTAS Y RECLAMACIONES</strong></h3>
				<div className='container text-start'>
					<p>MONEY GIVER, S.A.P.I. DE C.V., SOFOM, E.N.R. pone a disposición de sus usuarios los datos de la Unidad Especializada (UNE) para la atención de consultas y reclamaciones: </p>

					<p>Titular y Encargado regional: <b>Francisco Arturo Gutiérrez Ruíz</b></p>

					<p>En MONEY GIVER no tenemos sucursales.</p>

					<p className='lh-sm'>Oficina: Juan Salvador Agraz 97 Local E, Col. Lomas de Santa Fe, 05300 CDMX<br />
						Horario de atención: lunes a viernes de 9:00 a 17:00 horas, excepto días inhábiles oficiales comunicados por la CNBV a través del DOF.<br />
						Teléfono: (55)84210001<br />
						Correo electrónico: atencionaclientes@moneygiver.com.mx</p>
					<br></br>
					<p>Última actualización: <b>03 de septiembre de 2022</b></p>

					<p>De conformidad con lo establecido en el artículo 50 Bis de la Ley de Protección y Defensa al Usuario de Servicios Financieros, cada institución financiera deberá contar con una Unidad Especializada que tendrá por objeto atender consultas y reclamaciones de los Usuarios. Dicha Unidad se sujetará a o siguiente:</p>

					<p>El Titular de la Unidad deberá tener facultades para representar y obligar a la Institución Financiera al cumplimiento de los acuerdos derivados de la atención que se dé a la reclamación;</p>
					<p>Contará con encargados regionales en cada entidad federativa en que la Institución Financiera tenga sucursales u oficinas de atención al público;</p>
					<p>Los gastos derivados de su funcionamiento, operación y organización correrán a cargo de las Instituciones Financieras;</p>
					<p>Deberá recibir la consulta, reclamación o aclaración del Usuario por cualquier medio que facilite su recepción, incluida la recepción en las sucursales u oficinas de atención al público y responder por escrito dentro de un plazo que no exceda de treinta días hábiles, contado a partir de la fecha de su recepción, y</p>
					<p>El titular de la Unidad Especializada deberá presentar dentro de los diez días hábiles siguientes al cierre de cada trimestre, un informe a la Comisión Nacional de todas las consultas, reclamaciones y aclaraciones recibidas y atendidas por la Institución Financiera en los términos que la Comisión Nacional establezca a través de disposiciones de carácter general que para tal efecto emita.</p>
				</div>

			</div>

			<br id='contact'></br>



			<FooterLanding />
		</div>
	);

}