import React, {useState, useEffect} from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slide1Image from "../assets/banners/bannerMG1.png";
import Slide2Image from "../assets/banners/bannerMG2.png";
import Slide3Image from "../assets/banners/bannerMG3.png";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

const CaruselResponsivo = () => {
  const textLargeCustom = {
    fontSize: "350%",
  };

  const imagen1Width = {
    maxHeight: "400px",
  };

  const imagen2Width = {
    maxHeight: "800px",
  };

  const settings = {
    showArrows: false,
    interval: 4000,
    dynamicHeight: false,
    stopOnHover: true,
    infiniteLoop: true,
    showStatus: false,
    transitionTime: 1000,
    showThumbs: false,
    showIndicators: true,
    swipeable: true,
    emulateTouch: true,
    autoPlay: true,
  };

  const [maxWidth, setMaxWidth] = useState("75vw");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 800) {
        setMaxWidth("100vw");
      } else {
        setMaxWidth("75vw");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Para ajustar el tamaño al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Carousel className="pt-5 my-5" {...settings}>
      <img
        style={{ maxWidth: maxWidth, margin: "auto" }}
        className="img-fluid "
        src={Slide1Image}
        alt="..."
      />

      <img
        style={{ maxWidth: maxWidth, margin: "auto" }}
        className="img-fluid "
        src={Slide2Image}
        alt="..."
      />

      <img
        style={{ maxWidth: maxWidth, margin: "auto" }}
        className="img-fluid "
        src={Slide3Image}
        alt="..."
      />
    </Carousel>
  );
};

export default CaruselResponsivo;
