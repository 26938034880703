import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import image1Group from '../assets/group-33554.png'
import image2Group from '../assets/group-33554-1.png'
import { Row, Col, Image, Container  } from 'react-bootstrap';
import { EnterpriseBenefits } from './home/enterprise-benefits';
import { PersonalBenefits } from './home/personal-benefits';
function CardLandingMain() {

	const justify = {
		textAlign: 'justify',
	};

	return (
		<section className="m-5 p-auto">
			<h4 className='ralewayBold fs-1'><span className='verde-primario-texto'>Beneficios MoneyGiver </span>  </h4>
			<span className="ralewayRegular p-auto">En MoneyGiver, nos dedicamos a fomentar el crecimiento empresarial y a ayudar a los empleados a gestionar eficazmente sus finanzas personales empresas y colaborar con sus empleados a gestionar sus finanzas personales.</span>
			<p className='m-5'></p>	
			<Container>
				<Row className='align-items-center justify-content-center'>
					<EnterpriseBenefits></EnterpriseBenefits>
					<PersonalBenefits></PersonalBenefits>
				</Row>
			</Container>
			{/* <div className="container text-center">
				<div className="row row-cols-1 row-cols-sm-2 row-cols-md-2">
					<div className="col">
						<div className="card border border-0">
							<img src={image2Group} className="card-img-top" alt="..." />
							<div className="">
								<h5 className="card-title text-start fs-3 ralewayBold py-3">Beneficios empresariales</h5>
								<p className="card-text ralewayLight" style={justify}>Las empresas que deciden utilizar MoneyGiver para dispersar su nómina reciben nuestro apoyo en todo momento para impulsar su crecimiento y fortalecimiento. Nuestros sistemas digitales le ayudan a visualizar y organizar su actividad financiera, la línea de crédito los apoya en caso de emergencia y sus empleados reciben múltiples beneficios sin costo.</p>
							</div>
						</div>
					</div>
					<div className="col">
						<div className="card border border-0 ">
							<img src={image1Group} className="card-img-top img-fluid" alt="..." />
							<div className="">
								<h5 className="card-title text-start fs-3 ralewayBold py-3">Beneficios personales</h5>
								<p className="card-text ralewayLight" style={justify}>Los empleados que reciben su pago de nómina a través de MoneyGiver reciben múltiples beneficios que los apoyan en la gestión y control de sus recursos financieros, bonificaciones y los asiste en casos de imprevistos. </p>
							</div>
						</div>
					</div>
				</div>
			</div> */}
		</section>
	);

}

export default CardLandingMain;
