import React, { useState, useEffect } from 'react';
import { Form, Col, Button, Modal, Row, Image, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import EBanking from '../../assets/logo_moneygiver_ebanking.png';
import FormularioDinamico from '../formularioDinamico/FormularioDinamico';

export const FormularioPreAltaMerchant = () => {

	

	

	return (
		<div className="container  p-5 d-flex flex-column align-items-center justify-content-center ">

			<FormularioDinamico ></FormularioDinamico>


		</div>
	);
};

