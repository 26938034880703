import React from "react";
import Header from '../../header'
import Footer from '../../componets/footer-landing'
// custom components
import FormContact from "../../componets/form-concat";

// custom images
import EBanking from '../../assets/logo_moneygiver_ebanking.png';

import { Col, Image, Container } from 'react-bootstrap';

export const RegistroPage = (props) => {
    return (
        <div>
            <Header />
            <br className="m-5" />
            <Container className="p-5 d-flex flex-column align-items-center justify-content-center ">

                <Image src={EBanking} className='img-fluid pb-5' />
                <h2 className='ralewayBold verde-primario-texto text-center'>¡Estamos aquí para ayudarte!</h2>
                <p className='ralewayRegular my-3 lh-base fs-5 text-center'>Si estás interesado en explorar cómo podemos trabajar juntos para alcanzar estos objetivos, no dudes en enviarnos un mensaje.</p>
                <FormContact></FormContact>
            </Container>
            <Footer></Footer>
        </div>
    )
}