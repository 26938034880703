import React, { useState, useEffect } from "react";
import { ServicePostMerchantAdd } from "../../services/merchants/serviceMerchant";
import {
    Form,
    Col,
    Button,
    Modal,
    Row,
    Image,
    ToggleButton,
    ToggleButtonGroup,
    Spinner,
    Accordion,
    InputGroup,
} from "react-bootstrap";
import EBanking from "../../assets/logo_moneygiver_ebanking.png";
import CustomModal from "../shared/Modal";
import altaSchema from "./altaSchema.json";

export const FormularioAltaMerchant = () => {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({});
    const [validated, setValidated] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [modalBody, setModalBody] = useState("");
    const [modalButtonVariant, setModalButtonVariant] = useState("");
    const [modalButtonText, setModalButtonText] = useState("");
    const [modalMessageResponse, setModalMessageResponse] = useState("");

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleSuccessModal = (message) => {
        handleShowModal(
            "Formulario Enviado",
            message,
            "Cerrar",
            "primary"
        );
    };

    // Ejemplo de cómo utilizar el modal de error
    const handleErrorModal = (message, type) => {
        handleShowModal(
            "Aviso",
            message,
            "Cerrar",
            type
        );
    };

    const handleShowModal = (
        title,
        body,
        buttonText,
        buttonVariant,
        folioGrupoEmpresarial
    ) => {
        setModalTitle(title);
        setModalBody(body);
        setModalButtonText(buttonText);
        setModalButtonVariant(buttonVariant);
        setModalMessageResponse(folioGrupoEmpresarial);
        setShowModal(true);
    };


    const handleChange = (e) => {
        const { name = "", type, value } = e.target;
        let newErrors = { ...errors };

        // Validación: campo no vacío
        if (value.trim() === "") {
            newErrors[name] = "Este campo es requerido.";
        } else {
            delete newErrors[name];
        }

        // Validación: solo valores numéricos
        if (type === "number" && isNaN(value)) {
            newErrors[name] = "Este campo solo acepta valores numéricos.";
        }

        // Validación: RFC
        if (name === "rfc") {
            const rfcRegex = /^[A-ZÑ&]{3,4}\d{6}[A-Z\d]{3}$/i;
            if (!rfcRegex.test(value)) {
                newErrors[name] = "El RFC no es válido.";
            } else {
                delete newErrors[name];
            }
        }

        setFormData({ ...formData, [name]: value });
        setErrors(newErrors);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        setValidated(true);

        if (form.checkValidity()) {
            try {
                // Aquí transformamos formData a la estructura deseada
                const merchantData = transformFormData(formData);
               
                const response = await ServicePostMerchantAdd(merchantData);
                
            } catch (error) {
                console.error("Error al enviar los datos del formulario:", error);
                // Puedes actualizar el estado para mostrar un mensaje de error al usuario
            }
        }
    };
    const transformFormData = (data) => {
        return {
            nombreNegocio: data.nombreNegocio || "",
            razonSocial: data.razonSocial || "",
            descripcion: data.descripcion || "",
            rfc: data.rfc || "",
            direccion: {
                pais: data.pais || "",
                estado: data.estado || "",
                ciudad: data.ciudad || "",
                codigoPostal: data.codigoPostal || "",
                calle: data.calle || "",
                numeroExterior: data.numeroExterior || "",
                numeroInterior: data.numeroInterior || "",
                colonia: data.colonia || "",
                tiempoResidenciaAnios: parseInt(data.tiempoResidenciaAnios, 10) || 0,
            }
        };
    };

    return (
        <div className="container d-flex flex-column align-items-center justify-content-center">
            <Image src={EBanking} className="img-fluid pb-3" />
            <h5 className="text-center ralewayBold ms-2">
                Para culminar con su alta, ahora solo tendrá que proporcionar la siguiente información:
            </h5>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row>
                    {altaSchema.map((campo, index) => (
                        <Col
                            xs={12}
                            md={12}
                            xl={12}
                            className="my-3"
                            id={campo.id}
                            key={campo.id}
                        >
                            {campo.type === "file" ? (
                                <Form.Group controlId={campo.id} className="">
                                    <Form.Label className="ralewayRegular">
                                        {campo.title}
                                        {campo.required ? (
                                            <span className="text-danger">*</span>
                                        ) : null}
                                    </Form.Label>
                                    {!campo.description ? null : (
                                        <>
                                            <br /> <Form.Text>{campo.description}</Form.Text>
                                        </>
                                    )}
                                    <Form.Control
                                        type="file"
                                        name={campo.id}
                                        onChange={handleChange}
                                        required={campo.required ?? false}
                                    />
                                </Form.Group>
                            ) : (
                                <Form.Group controlId={campo.title}>
                                    <Form.Label className="ralewayRegular">
                                        {campo.title}
                                        {campo.required ? (
                                            <span className="text-danger">*</span>
                                        ) : null}
                                    </Form.Label>
                                    <Form.Control
                                        type={campo.type}
                                        name={campo.name}
                                        onChange={handleChange}
                                        required={campo.required ?? false}
                                        isInvalid={!!errors[campo.name]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors[campo.name] || "Este campo es requerido."}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            )}
                        </Col>
                    ))}
                </Row>
                <Button type="submit">Submit</Button>
            </Form>
            <CustomModal
                show={showModal}
                onHide={handleCloseModal}
                title={modalTitle}
                body={modalBody}
                buttonText={modalButtonText}
                buttonVariant={modalButtonVariant}
                responseContent={modalMessageResponse}
            />
        </div>
    );
};
