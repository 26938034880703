const DocumentTypeEnum = {
    1: "Acta constitutiva",
    2: "Testimonio de poderes",
    3: "Comprobante de domicilio",
    4: "Identificación oficial",
    5: "Constancia de situación fiscal",
    6: "Opinión de cumplimiento",
    7: "Anexo de comisiones",
    8: "Solicitud de apertura de cuenta",
    9: "Testimonio para otorgar facultades para la firma del contrato que fungirá como representante legal",
    10: "Testimonio que protocolice el cambio de razón social"
};

const DocumentTypeDescriptionEnum = {
    1: "Documento obligatorio que da constancia y legalidad a la constitución de una sociedad al momento de crear una empresa",
    2: "Documento obligatorio que da constancia y legalidad a la constitución de una sociedad al momento de crear una empresa",
    3: "Documento que se utiliza para comprobar el lugar de residencia o domicilio de una persona física o moral",
    4: "Documento que permite acreditar legalmente la identidad de una persona",
    5: "Documento que contiene datos de identidad, ubicación y caracterísitcas fiscales del contribuyente",
    6: "Documento que las personas físicas y morales solicitan al SAT en caso de que quieran hacer un contrato con el gobierno, solicitar un estímulo, hacer un trámite fiscal o de comercio exterior",
    7: "Documento que detalla los impuestos correspondientes a las comisiones",
    8: "Documento que permite la creación de un nuevo cliente empresarial",
    9: "Documento que acredita a una persona como representante legal de la empresa",
    10: "Documento que permite hacer uso de la nueva denomiación social"
};

const DocumentTypeEnumAlter = {
    2: "Acta constitutiva",
    3: "Testimonio de poderes",
    4: "Comprobante de domicilio",
    5: "Identificación oficial",
    6: "Constancia de situación fiscal",
    7: "Opinión de cumplimiento",
    8: "Anexo de comisiones",
    9: "Solicitud de apertura de cuenta",
    10: "Testimonio para otorgar facultades para la firma del contrato que fungirá como representante legal",
    11: "Testimonio que protocolice el cambio de razón social"
};

const DocumentTypeDescriptionEnumAlter = {
    2: "Documento obligatorio que da constancia y legalidad a la constitución de una sociedad al momento de crear una empresa",
    3: "Documento obligatorio que da constancia y legalidad a la constitución de una sociedad al momento de crear una empresa",
    4: "Documento que se utiliza para comprobar el lugar de residencia o domicilio de una persona física o moral",
    5: "Documento que permite acreditar legalmente la identidad de una persona",
    6: "Documento que contiene datos de identidad, ubicación y caracterísitcas fiscales del contribuyente",
    7: "Documento que las personas físicas y morales solicitan al SAT en caso de que quieran hacer un contrato con el gobierno, solicitar un estímulo, hacer un trámite fiscal o de comercio exterior",
    8: "Documento que detalla los impuestos correspondientes a las comisiones",
    9: "Documento que permite la creación de un nuevo cliente empresarial",
    10: "Documento que acredita a una persona como representante legal de la empresa",
    11: "Documento que permite hacer uso de la nueva denomiación social"
};


export const getDocumentTypeById = (id) => {
    return DocumentTypeEnum[id] || "Tipo de documento desconocido";
}

export const getDocumentDescriptionById = (id) => {
    return DocumentTypeDescriptionEnum[id] || "Sin descripción";
}

export const getDocumentTypeByIdAlter = (id) => {
    return DocumentTypeEnumAlter[id] || "Tipo de documento desconocido";
}

export const getDocumentDescriptionByIdAlter = (id) => {
    return DocumentTypeDescriptionEnumAlter[id] || "Sin descripción";
}