import React, { useState } from 'react';
import { Row, Col, Image } from 'react-bootstrap';

//Images
import enterpriseImage from "../../assets/people/3. Empresariales.jpg"
import iconTexts from '../../assets/icon-texts.svg'

export const EnterpriseBenefits = () => {
    const justify = {
        textAlign: 'justify',
    };
    return (
        <>
            <Col className='d-lg-flex flex-lg-row flex-sm-column align-items-center justify-content-between' xs={12} md={12} lg={12}>
                <Image style={{ maxWidth: "40vw" }} className='img-fluid' src={enterpriseImage} alt='...'></Image>
                <div className='mx-3'>
                    <h4 className="card-title text-start  ralewayBold py-3">Beneficios empresariales</h4>
                    <p className="card-text ralewayLight" style={justify}>Las empresas que deciden utilizar la plataforma MoneyGiver reciben nuestro apoyo en todo momento para impulsar su crecimiento y fortalecimiento. Nuestros sistemas digitales le ayudan a visualizar y organizar su actividad financiera, la línea de crédito los apoya en caso de emergencia y sus empleados reciben múltiples beneficios sin costo.</p>
                    <div className='d-flex flex-column align-items-start'>
                        {/* <img style={{verticalAlign:"start"}} src={iconTexts} alt='...' className='img-fluid'></img> */}
                        <h4 className='ralewayBold text-start'>Seguridad y privacidad en tus recursos financieros</h4>
                        <p className='ralewayLight pt-2' style={justify}>Mantén la seguridad de tu empresa y de tus colaboradores, confiando que tus recursos estarán seguros con transacciones validadas y respaldadas por las principales instituciones financieras. Tu decidirás quien puede visualizar toda gestión de los recursos manteniendo la privacidad.</p>
                    </div>
                </div>


            </Col>
        </>
    )
}