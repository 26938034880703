import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

function buttonJoinMoneyGiver() {



	return (

		<ScrollLink className="btn btn btn-success"
			to="contact"
			smooth={true}
			duration={800}  // Ajusta la duración según tus necesidades (en milisegundos)
		>Únete a MoneyGiver</ScrollLink>



	);
}

export default buttonJoinMoneyGiver;
