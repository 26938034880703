import { ApiPostMerchantAdd } from "./merchantApi.tsx";


export const ServicePostMerchantAdd = async (merchantData) => {
    const response = await ApiPostMerchantAdd(merchantData)
    //console.log(response);
    try {
        if (response.httpStatusCode === 200) {
            return response.data
        }else if (response.httpStatusCode === 100){
           // console.log(response.message);
            return response.data
        }
    } catch (error) {
        console.log(error)
        return response

    }
}