import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import QR from "../assets/qrs/MoneyGiver Promotional.png";
import { Helmet } from 'react-helmet';
import LogoMG512 from "../assets/logo512.png"
import LogoMG192 from "../assets/logo192.png"
import { Card, Button } from 'react-bootstrap';
import googlePlay from "../assets/icons/GetItOnGooglePlay.png"
import AppStore from "../assets/icons/DownloadAppStoreBadgejpg.jpg"
import "../styles/promocion.css"

export const PromotionPage = () => {
    const [link, setLink] = useState('https://app.moneygiver.xyz/');
    const [widthCard, setWidthCard] = useState('20vw');
    const [message, setMessage] = useState('Serás redirigido a la aplicación web de escritorio');
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [containerChange, setContainerChange] = useState("container")
    const [background, setBackground] = useState("p-5")

    const formatPageName = (pageName) => {
        return pageName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const location = useLocation();

    useEffect(() => {
        const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
        const formattedPageName = formatPageName(pageName);
        document.title = `${formattedPageName}`;
    }, [location.pathname]);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            setLink('https://play.google.com/store/apps/details?id=mx.com.moneygiverwallet.app');
            setMessage('Serás redirigido a la aplicación móvil de Android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setLink('https://apps.apple.com/mx/app/money-giver/id6473800031');
            setMessage('Serás redirigido a la aplicación móvil de IOS');
        } else {
            setLink('https://app.moneygiver.xyz/');
            setMessage('Serás redirigido a la aplicación web de escritorio');
        }

        const handleResize = () => {
            setWindowWidth(window.innerWidth);
            if (window.innerWidth <= 700) {
                setWidthCard('auto');
                setContainerChange("containerPromo")
                setBackground("")
            } else if (window.innerWidth <= 1000) {
                setWidthCard('50vw');
                setContainerChange("container mt-5")
                setBackground("p-5")
            } else {
                setWidthCard('30vw');
                setContainerChange("container mt-5")
                setBackground("p-5")
            }
        };

        window.addEventListener('resize', handleResize);

        // Check initial width
        if (window.innerWidth <= 700) {
            setWidthCard('auto');
            setContainerChange("containerPromo")
            setBackground("")
        } else if (window.innerWidth <= 1000) {
            setWidthCard('50vw');
            setContainerChange("container mt-5 ")
            setBackground("p-5")
        } else {
            setWidthCard('30vw');
            setContainerChange("container mt-5 ")
            setBackground("p-5")
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = link;
        }, 5000);

        return () => clearTimeout(timer);
    }, [link]);



    return (
        <div className={background} style={{ background: "#F1EFEF", width: "100vw", height: "100vh" }}>
            <div id="" className={`${containerChange} p-5`}>
                <Helmet>
                    <meta name="robots" content="noindex" />
                </Helmet>
                <div className='d-flex flex-column align-items-center justify-content-between'>
                    <div style={{ width: widthCard, overflow: "visible" }} className="card">
                        <div className="card-image-wrapper">
                            <div className="card-logo" >
                                <img className='img-fluid' style={{ padding: "35px" }} src={LogoMG512} alt="Logo" />
                            </div>
                        </div>
                        <div className="card-content">
                            <br></br>
                            <h2 className='azul-primario-texto'>MoneyGiver</h2>
                            <br></br>
                            <p><a target="_blank"
                                rel="noopener noreferrer" href="/"><span className="ralewayRegular">Nuestra página web</span></a></p>

                            <p style={{ fontSize: "12px" }} className='ralewayLight'>Somos expertos en tecnología financiera con herramientas innovadoras.</p>
                            <div className="app-links">
                                <a target="_blank"
                                    rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=mx.com.moneygiverwallet.app"><img src={googlePlay} alt="Play Store" className="app-link-image" /></a>
                                <br className='m-2' />
                                <a target="_blank"
                                    rel="noopener noreferrer" href="https://apps.apple.com/mx/app/money-giver/id6473800031"><img src={AppStore} alt="App Store" className="app-link-image" /></a>
                            </div>
                            <br />
                            <p style={{ fontSize: "11px" } } className='ralewayLight'>{message}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
