import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import splash from '../../assets/as2as5a5ccb5cb5cb5551899878cb8c8b74651bc.jpg';
import '../../App.css';

function RegistroTituloArrendamiento() {
    const titulo = {
        fontFamily: "RalewayBold",
        fontWeight: 700,
        fontSize: 36
    };

    const subtitulo = {
        fontFamily: "RalewayRegular",
        fontWeight: 400,
        fontSize: "12pt"
    };

    const ahora = {
        color: "#32ac61",
    };

    const center = {
        textAlign: "center",
    };

    return (
        <Container>
            <Row>
                <Col style={center}>
                    <h3 style={titulo}><strong>Regístrate <span style={ahora}>ahora</span></strong></h3>
                    <span style={subtitulo}>Tu información no se compartirá con terceros y solo nos pondremos en contacto contigo en relación con MoneyGiver.</span>
                </Col>
            </Row>
        </Container>
    );
}

export default RegistroTituloArrendamiento;