
import './App.css';
import Header from './header.js'
import 'bootstrap/dist/css/bootstrap.css';
import Main from './Main';
import FooterLanding from './componets/footer-landing';
import FloatingButton from './componets/button-go-up';
import WhatsAppButton from './componets/button-go-whats'


function Body() {


    const noHorizontal = {
        overflow: 'hidden'
    };

    return (
        <div id="home" className="App" style={noHorizontal}>

            <Header />
            <FloatingButton />
            <WhatsAppButton />
            <section>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='column'></div>
                    </div>
                </div>
            </section>
            <section>
                <div className=''>
                    <Main />
                </div>
            </section>
            <FooterLanding />
        </div>
    );
}
export default Body;