import React, { useState } from 'react';
import { Form, Col, Button, Modal, Row, Image, Placeholder, Card, CardGroup } from 'react-bootstrap';
import EBanking from '../../assets/logo_moneygiver_ebanking.png';
import NotificationToast from '../notificacion';
export const Unsubscribe = () => {

	const title = {
		fontSize: '1.75rem',
		textAlign: 'center',
	}

	const bodyText = {
		fontSize: '1.15rem',
		paddingLeft: '1rem',
		paddingRight: '1rem',
	}

	const [formData, setFormData] = useState({
		email: "",
	})

	const [showA, setShowModal] = useState(false);
	const [validated, setValidated] = useState(false);
	const [messageNotification, setMessageNotification] = useState("");
	const [type, setTypeNotification] = useState("");
	const [titleNotification, setTittleNotification] = useState("")
	const toggleShowA = () => {
		setShowModal(!showA)
	};

	const handleChange = (e) => {

		const { name, type, value } = e.target;

		setFormData({ ...formData, [name]: value });

	};


	const handleSubmit = (e) => {
		e.preventDefault();
		const form = e.currentTarget;

		if (form.checkValidity()) {
			const error = true;

			//console.log('Datos del formulario:', formData);

			if (error) {
				setShowModal(true);
				setMessageNotification("Hubo un error al realizar la petición vuelva intentarlo");
				setTittleNotification("Error en la petición");
				setTypeNotification("error");
			}

			setShowModal(true);
		}

		setValidated(true);
	};


	return (

		<div style={{ height: '100vh' }} className=' d-flex flex-column align-items-center justify-content-center '>
			<Card style={{ width: '50vw', margin: "5rem", alignItems: 'center' }}>
				<Image style={{ maxWidth: '40vw', }} className='pt-5' src={EBanking}></Image>
				<br />
				<Card.Body className='text-aling-center  d-flex flex-column align-items-center'>
					<Card.Title style={title}>¡Te extrañaremos!</Card.Title>
					<br />
					<Card.Text style={bodyText}>
						Solicitarás desuscribirte de MoneyGiver y asociados solo proporciona tu correo y se validará
						si existe para continuar
					</Card.Text>



					<Form  style={{ width: "45vw"}} noValidate validated={validated} onSubmit={handleSubmit}>
						<Row >
							<Col className='my-3'>
								<Form.Group controlId="email">
									<Form.Label className='ralewayRegular'>Correo electrónico <span className="text-danger">*</span></Form.Label>
									<Form.Control
										type="email"
										name="email"
										value={formData.email}
										onChange={handleChange}
										required
									/>
									<Form.Control.Feedback type="invalid">
										Por favor, ingresa un correo electrónico válido.
									</Form.Control.Feedback>
								</Form.Group>
							</Col>
							<Row>
								<Col>
								<Button type="submit" style={{ background: '#dc3545', borderColor: '#dc3545' }} className="mb-2 ">
							<strong>Desuscribirse</strong>
						</Button></Col>
							</Row>
						</Row>
						
					</Form>
				</Card.Body>


			</Card>

			<NotificationToast title={titleNotification} message={messageNotification} type={type} show={showA} onClose={toggleShowA} />
		</div>
	);
}