import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/bootstrap-icons.svg";
import moneyGiverEbanking from "../assets/logomoneygiverebanking_white.png";
import { useLocation, Link } from "react-router-dom";
import googlePlay from "../assets/google_play.png";
import Apple from "../assets/2560px-download_on_the_app_store_badge_esmx_rgb_blk-svg.png";
import inai from "../assets/inai.png";
import fintech from "../assets/logo_fintech_mexico_blank.png";
import buro from "../assets/buro.png";
import educa from "../assets/educa_tu_cartera.png";
import cnvb from "../assets/cnvb.png";
import condusef from "../assets/condusef.png";

import { Row, Col, Container } from "react-bootstrap";

function FooterLanding() {
  const imgAppStores = {
    width: "64px",
  };

  const imgGooglePlay = {
    width: "78px",
  };

  const companyImgsSmall = {
    with: "64px",
    maxWidth: "64px",
  };

  const location = useLocation();
  const isActive = (pathname) =>
    location.pathname === pathname ? "active-link" : "";

  return (
    <footer className=" footer-custom pt-3">
      <div className="container  text-white ">
        <div className="d-sm-flex flex-md-row flex-sm-column align-items-center align-content-stretch text-start ">
          <div className="d-flex flex-column align-items-center">
            <div className="mb-3 d-flex flex-column  align-items-center">
              <Col md={8} className=" ">
                <img
                  style={{ width: "15vw", margin: "auto" }}
                  src={moneyGiverEbanking}
                  alt="..."
                  className="img-fluid "
                ></img>
              </Col>
            </div>
          </div>

          <div className="flex-grow-0 mx-5">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a
                  className={`ralewayRegular white-link ${isActive("/legales")}`}
                  href="/legales"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <p>Legales</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-grow-0 mx-5">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a
                  className={`ralewayRegular white-link ${isActive("/sobre_nosotros")}`}
                  href="/sobre_nosotros"
                >
                  <p>Acerca de</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-grow-0 mx-5">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a
                  className={`ralewayRegular white-link ${isActive("/nomina")}`}
                  href="/nomina"
                >
                  <p>Nómina</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-grow-0 mx-5">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a
                  className={`ralewayRegular white-link ${isActive("/credito/credito_MG")}`}
                  href="/credito/credito_MG"
                >
                  <p>Crédito</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-grow-0 mx-5">
            <ul className="navbar-nav">
              <li className="nav-item ">
                <a
                  className={`ralewayRegular white-link ${isActive("/registro")}`}
                  href="/registro"
                >
                  <p>Contáctanos</p>
                </a>
              </li>
            </ul>
          </div>
          <div className="flex-grow-1">
            <span id="siteseal">
              <script
                async
                type="text/javascript"
                src="https://seal.godaddy.com/getSeal?sealID=MWnoDZpFMl722LkK1j7uWp6VnthjJZLM8En7Zr90y4oVp0a1GGaGoVoQHOcq"
              ></script>
            </span>
          </div>
        </div>

        <div className="py-2 d-flex  align-items-start align-content-stretch text-start">
          <small
            style={{ fontSize: "10px" }}
            className="nav-item ralewayLight  lh-lg "
          >
            MONEY GIVER, S.A.P.I. DE C.V., SOFOM, E.N.R. no requiere
            autorización de la Secretaría de Hacienda y Crédito Público (SHCP)
            para su constitución y operación, y está sujeta a la supervisión de
            la Comisión Nacional Bancaria y de Valores (CNBV) en materia de
            prevención de operaciones con recursos de procedencia ilícita y
            financiamiento al terrorismo en términos del artículo 56 y 95 Bis de
            la Ley General de Organizaciones y Actividades Auxiliares del
            Crédito.
          </small>
        </div>

        <div className="d-flex align-items-center justify-content-center flex-row flex-wrap">
          <a
            href="https://apps.apple.com/mx/app/money-giver/id6473800031"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img-fluid me-3 "
              style={imgAppStores}
              src={Apple}
              alt="..."
            ></img>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=mx.com.moneygiverwallet.app"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="img-fluid "
              style={imgGooglePlay}
              src={googlePlay}
              alt="..."
            ></img>
          </a>
        </div>

        <div className="d-flex flex-wrap justify-content-center align-items-center">
          <a
            href="https://home.inai.org.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={inai}
              alt="..."
            ></img>
          </a>
          <a
            href="https://webappsos.condusef.gob.mx/EducaTuCartera/index.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={educa}
              alt="..."
            ></img>
          </a>
          <a
            href="https://www.gob.mx/cnbv"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={cnvb}
              alt="..."
            ></img>
          </a>
          <a
            href="https://www.buro.gob.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={buro}
              alt="..."
            ></img>
          </a>
          <a
            href="https://www.condusef.gob.mx/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={condusef}
              alt="..."
            ></img>
          </a>
          <a
            href="https://www.fintechmexico.org/?fbclid=IwAR1XnSHfVjaVnPAD3N9U7HEcboiOyEViwhnxTZqv8-hU5p80D-suI4sW7e8"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="mx-4 img-fluid"
              style={companyImgsSmall}
              src={fintech}
              alt="..."
            ></img>
          </a>
        </div>
        <div className="d-flex flex-column align-items-center justify-contenet">
          <div
            style={{ fontSize: "10px" }}
            className="ralewayLight  text-white lh-lg"
          >
            Avenida Ciudad Universitaria 286 1er piso, Jardines del Pedregal,
            Alcaldía Alvaro Obregón C.P.01900, Ciudad de México.
          </div>
          <div className="d-flex  align-items-start  text-start ">
            <p style={{ fontSize: "10px" }} className="">
              © 2024 MoneyGiver
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default FooterLanding;
