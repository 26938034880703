import Toast from 'react-bootstrap/Toast';
import 'bootstrap-icons/font/bootstrap-icons.css'
import { useEffect } from 'react';
function NotificationToast({ title, message, type, show, onClose }) {

    const renderHeader = (type) => {

        if (type === "error") {
            return (
                <>
                    <i className="me-1 text-danger bi bi-exclamation-circle" style={{ fontSize: '1.25rem' }}></i>
                    <strong className="me-auto text-danger">{title}</strong>
                </>


            )
        }
        else if (type === "success") {
            return (
                <>
                    <i className="me-1 text-success bi bi-check-circle" style={{ fontSize: '1.25rem' }}></i>
                    <strong className="me-auto text-success">{title}</strong>
                </>

            )
        }
        else {
            return (
                <>
                    <i className="me-1 bi bi-info-circle" style={{ fontSize: '1.25rem', color: "cornflowerblue" }}></i>
                    <strong className="me-auto" style={{ color: "cornflowerblue" }}>{title}</strong>
                </>

            );
        }

    }

    useEffect(() => {
        if (show) {
          const timer = setTimeout(() => {
            onClose(); // Cierra el Toast después de 3 segundos
          }, 3000);
          return () => clearTimeout(timer);
        }
      }, [show, onClose]);

    return (
        <Toast className="position-fixed bottom-0 end-0 m-2" style={{ zIndex: '999' }} show={show} onClose={onClose}>
            <Toast.Header>
                {renderHeader(type)}

            </Toast.Header>
            <Toast.Body>{message}</Toast.Body>
        </Toast>
    );
}

export default NotificationToast;