import React, {useState, useEffect} from "react";

import CardMG from "../../assets/devices/11- IPHONE WEB2.png";


import { Container } from "react-bootstrap";

export const Section2 = () => {
  const textSize = {
    fontSize: "14px",
  };
  const [maxWidth, setMaxWidth] = useState("25vw");

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setMaxWidth("80vw");
      } else if (window.innerWidth <= 800) {
        setMaxWidth("50vw");
      } 
	  
	  else {
        setMaxWidth("25vw");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Para ajustar el tamaño al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="banner-circules-mg  py-4">
      <Container>
        <div className="d-flex flex-lg-row flex-column justify-content-between align-items-center my-5">
          <div className="text-start">
            <div className="my-4">
              <h3 className="text-wrap ralewayBold">
                Te ayudamos a que tu negocio crezca
              </h3>
              <p className="text-wrap ralewayRegular" style={textSize}>
                Somos una empresa especializada en servicios financieros
                digitales cuyo principal objetivo es proteger y hacer crecer tu
                negocio.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-wrap ralewayBold">
                Línea de crédito empresarial en cualquier momento
              </h3>
              <p className="text-wrap ralewayRegular" style={textSize}>
                Siéntete seguro de que siempre contarás con el apoyo de
                MoneyGiver para tener flujo de efectivo inmediato, que te
                permitirá cumplir con tus compromisos financieros.
              </p>
            </div>
          </div>
          <div className="">
            <img
              style={{ maxWidth: maxWidth }}
              src={CardMG}
              alt="..."
              className="img-fluid"
            />
          </div>
          <div className="text-start">
            <div className="my-4">
              <h3 className="text-wrap ralewayBold">
                Mantén tus recursos financieros seguros
              </h3>
              <p className="text-wrap ralewayRegular" style={textSize}>
                En MoneyGiver nos encargamos de brindar seguridad a tu empresa y
                ofreciendo adelantos de nomina y préstamos cualquier imprevisto.
              </p>
            </div>
            <div className="my-4">
              <h3 className="text-wrap ralewayBold">Servicios digitales</h3>
              <p className="text-wrap ralewayRegular" style={textSize}>
                Las herramientas online de MoneyGiver te permiten mantener en
                orden y control los recursos financieros de tu empresa y con
                ello proyectar su crecimiento
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};
