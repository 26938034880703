import 'bootstrap/dist/css/bootstrap.css';
import creditCard from '../assets/cards/10-TARJETAS CIRCULO.png'
import cardTextImage from "../assets/cards/TARJETAS-TEXTO.png"



function CreditCardLandingGeneral() {

	const justify = {
		textAlign: 'justify',
	};

	const maxHeight = {
		maxHeight: '100%',
	};

	return (
		<section className="container-lg">
			<div className="m-5">
				<div className="row align-items-center justify-content-center ">
					{/* <div className="col-md d-flex align-items-center">
						<div className='container-fluid text-center d-flex justify-content-center'>
							<img style={{maxWidth:"35vw"}} className='img-fluid' src={creditCard} alt='...' />
						</div>
					</div>
					<div className="col " style={maxHeight}>
						<div className='p-3 m-3'>
							<br className='m-5'></br>
							<h4 style={justify} className=' ralewayLight lh-base '>Con MoneyGiver pueden solicitar una tarjeta  respaldada por <b>Mastercard</b>, pagar con ella en comercios nacionales e internacionales y comprar en establecimientos físicos y plataformas digitales.</h4>
							<p className='ralewayLight '></p>
							<br className='m-5'></br>
						</div>
					</div> */}
					<div className="col">
						<img alt='...' src={cardTextImage} className='img-fluid'></img>
					</div>
				</div>
			</div>
		</section>


	);

}

export default CreditCardLandingGeneral;
