import Header from "../../header.js";
import "bootstrap/dist/css/bootstrap.css";
import FooterLanding from "../../componets/footer-landing";
import FloatingButton from "../../componets/button-go-up";
import WhatsAppButton from "../../componets/button-go-whats";
import ButtonJoinMG from "../../componets/button-join-moneygiver";
import FormContact from "../../componets/form-concat.js";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import ImgPersonal from "../../assets/people/13. Préstamos - seguro.jpg";
import { Section2Credito } from "../../componets/creditoMG/section2.js";
import { Section3Credito } from "../../componets/creditoMG/section3.js";
import { useLocation } from "react-router-dom";
import React, { useEffect } from "react";
export const CreditoMG = () => {
  const formatPageName = (pageName) => {
    // Convertir "sobre_nosotros" a "Sobre nosotros"
    return pageName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const location = useLocation();

  useEffect(() => {
    // Extraer el nombre de la página de la ruta
    const pageName =
      location.pathname.split("/").filter(Boolean).pop() || "Inicio";

    // Formatear el nombre de la página
    const formattedPageName = formatPageName(pageName);

    // Cambiar dinámicamente el título de la página
    document.title = `Crédito`;
  }, [location.pathname]);
  return (
    <div id="home">
      <Header />
      <FloatingButton />
      <WhatsAppButton />

      <div className=" my-5 py-4 text-start">
        <section className="container my-5">
          <div className="row align-items-center justify-content-around">
            <div className="col mt-4">
              <h1 className="ralewayBold verde-primario-texto">
                Recibe tu <span>nómina</span>
                <br />
                en MoneyGiver
              </h1>
              <p>Préstamos sin aval ni garantías.</p>
              <p>
                Si tienes alguna emergencia, puedes solicitar un préstamo
                inmediato.
              </p>

              {/* <ScrollLink
                className="button-mg-small ralewayBold text-decoration-none text-nowrap"
                to="contact"
                smooth={true}
                duration={800} // Ajusta la duración según tus necesidades (en milisegundos)
              >
                Únete a MoneyGiver
              </ScrollLink> */}
            </div>
            <div className="col">
              <img
                style={{ maxWidth: "40vw" }}
                src={ImgPersonal}
                alt="..."
                className="img-fluid d-none d-md-inline"
              />
              <img
                style={{ maxWidth: "60vw", margin: "auto" }}
                className="d-sm-inline d-md-none"
                src={ImgPersonal}
                alt="..."
              ></img>
            </div>
          </div>
        </section>
        <Section2Credito />
        {/* <Section3Credito /> */}
        <br id="contact" />
      </div>

      {/* 
			<h1 className='text-center ralewayBold py-2'>Regístrate <span className='verde-primario-texto'>ahora</span></h1>

			<div className='container my-5'>
				<FormContact />
			</div> */}

      <FooterLanding />
    </div>
  );
};
