import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { Modal } from 'react-bootstrap/';
import Image from 'react-bootstrap/Image';
import '../../App.css';

function FormularioArrendamientoContent() {
    const [envioExitoso, setEnvioExitoso] = useState(false);
    const [formularioValues, setFormularioValues] = useState({
        nombre: '',
        apellidos: '',
        telefono: '',
        email: '',
        empresa: '',
        hnpt: '',
        mensaje: '',
    });

    const handleEnvioFormulario = (event) => {
        // Lógica para enviar el formulario, por ejemplo, enviar a una API
        // Después de enviar, actualiza el estado para mostrar el modal de éxito
        setEnvioExitoso(true);

        // Resetear los valores de los campos
        setFormularioValues({
            nombre: '',
            apellidos: '',
            telefono: '',
            email: '',
            empresa: '',
            hnpt: '',
            mensaje: '',
        });

        event.preventDefault(); // Evitar que el formulario se envíe realmente
    };

    const handleCloseModal = () => {
        setEnvioExitoso(false);
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormularioValues({
            ...formularioValues,
            [name]: value,
        });
    };

    return (
        <Container>
            <Row>
                <Col className="mb-3">
                    <Form onSubmit={handleEnvioFormulario}>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_nombre">
                            <Form.Label>Nombre <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="nombre"
                                value={formularioValues.nombre}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_apellidos">
                            <Form.Label>Apellidos <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="apellidos"
                                value={formularioValues.apellidos}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_telefono">
                            <Form.Label>Teléfono <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="telefono"
                                value={formularioValues.telefono}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_email">
                            <Form.Label>Correo electrónico <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="email"
                                name="email"
                                placeholder="ejemplo@mail.com"
                                value={formularioValues.email}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_empresa">
                            <Form.Label>Empresa <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                type="text"
                                name="empresa"
                                value={formularioValues.empresa}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_hnpt">
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                                type="text"
                                name="hnpt"
                                value={formularioValues.hnpt}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group className="mb-3 ralewayRegular" controlId="form1_mensaje">
                            <Form.Label>Mensaje <span style={{ color: 'red' }}>*</span></Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="mensaje"
                                value={formularioValues.mensaje}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                        <button type="submit" className="button-mg-medium w-50">
                            Unirme a MoneyGiver
                        </button>
                    </Form>

                    <Modal show={envioExitoso} onHide={handleCloseModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Datos enviados</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>Información de contacto mandada correctamente</p>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={handleCloseModal}>
                                Cerrar
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </Col>
            </Row>
        </Container>);
}

export default FormularioArrendamientoContent;