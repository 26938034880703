import React from "react";
import { Stack, Container } from "react-bootstrap";


export const CarrierPage = () => {
    return (
        <Container>
            <Stack  direction="vertical"  gap={3}>
                <Stack className="text-center">
                    <h1 className="fw-semibold ">Aviso imporante</h1>
                </Stack>
            
                <Stack >
                    <embed style={{height:"100vh"}}  src={"/documents/CardCarrierVisa OCT24.pdf"}></embed>
            
                </Stack>
            </Stack>
        </Container>
    );
}