import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import QR from "../assets/qrs/Benemax.png";
import { Helmet } from 'react-helmet';
export const BenemaxPage = () => {
    const [link, setLink] = useState('https://play.google.com/store/apps/details?id=com.addiuva_sin_fronteras&pcampaignid=');
    const [message, setMessage] = useState('¿Necesitas asistencia? Descarga nuestra app en Android');

    const formatPageName = (pageName) => {
        // Convertir "sobre_nosotros" a "Sobre nosotros"
        return pageName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const location = useLocation();

    useEffect(() => {
        // Extraer el nombre de la página de la ruta
        const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

        // Formatear el nombre de la página
        const formattedPageName = formatPageName(pageName);

        // Cambiar dinámicamente el título de la página
        document.title = `${formattedPageName}`;
    }, [location.pathname]);

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/android/i.test(userAgent)) {
            setLink('https://play.google.com/store/apps/details?id=com.addiuva_sin_fronteras&pcampaignid=');
            setMessage('¿Necesitas asistencia? Descarga nuestra app en Android');
        } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setLink('https://apps.apple.com/mx/app/addiuva-sin-fronteras/id6444068686');
            setMessage('¿Necesitas asistencia? Descarga nuestra app en IOS');
        } else {
            setLink('https://play.google.com/store/apps/details?id=com.addiuva_sin_fronteras&pcampaignid=');
            setMessage('¿Necesitas asistencia? Descarga nuestra app en Android');
        }
    }, []);

    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = link;
        }, 2000);

        return () => clearTimeout(timer);
    }, [link]);

    return (
        <div id="" className='container p-5'>
             <Helmet>
                <meta  name="robots" content="noindex" />
            </Helmet>
            <div className='d-flex flex-column align-items-center justify-content-between'>
                <p>{message}</p>
                <a href={link} border='0' style={{ cursor: "pointer", display: "block" }}>
                    <img className='img-fluid' src={QR} alt='QR IMG' />
                </a>
            </div>
        </div>
    );
};
