import Header from '../../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../../componets/footer-landing';
import FloatingButton from '../../componets/button-go-up';
import WhatsAppButton from '../../componets/button-go-whats';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

import { Container } from 'react-bootstrap';

export const LegalesPage = () => {
    const formatPageName = (pageName) => {
        // Convertir "sobre_nosotros" a "Sobre nosotros"
        return pageName
            .split('_')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    const location = useLocation();

    useEffect(() => {
        // Extraer el nombre de la página de la ruta
        const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

        // Formatear el nombre de la página
        const formattedPageName = formatPageName(pageName);

        // Cambiar dinámicamente el título de la página
        document.title = `${formattedPageName}`;
    }, [location.pathname]);

    return (
        <div style={{maxWidth:"100"}} id='home'>
            <Header />
            <FloatingButton />
            <WhatsAppButton />
            <div className='my-5 py-4 text-center '>
                <div className='text-center '>
                    <Container className='my-5 py-4'>
                       
                        <p className="nav-item ralewayLight  lh-lg ">
                                    ¿Quieres darte de baja para dejar de recibir novedades? Haz clic{" "}
                                    <a className='verde-primario-texto' style={{textDecoration: "none"}} href="/unsubscribe">
                                        aquí... <i className="bi bi-envelope-slash-fill"></i>
                                    </a>{" "}
                                </p>
                        <h4 className="ralewayBold pt-5 lh-base negro-primario-texto"><strong>Página de legales</strong></h4>
                    </Container>

                    <Container className='text-center my-5 py-4 '>                   
                        <ul className="navbar-nav">

                            <li className="nav-item">
                                <a
                                    className="blue-link"
                                    href="/documents/220930 Aviso_Privacidad_Money_Giver.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Aviso de privacidad
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="blue-link"
                                    href="/documents/220930_Aviso_Buro_Entidades_Financieras.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Aviso de buro de entidades financieras
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="blue-link"
                                    href="/documents/220930 Aviso_Datos_de_la_UNE.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Avisos de datos de la UNE
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className="blue-link"
                                    href="/documents/220930 Aviso_Despachos_de_Cobranza.pdf"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Cobranza
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className="blue-link" href="/servicios_legales">
                                    Servicios legales
                                </a>
                            </li>
                        </ul>
                    </Container>

                </div>
            </div>
            <FooterLanding />
        </div>
    );

}