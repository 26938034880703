import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.css';
import Slide1Image from '../assets/moneygiver.png'
import Slide2Image from '../assets/1463-1.png'
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const CarouselComponet = () => {



	const settings = {
		showArrows: false,
		interval: 4000,
		dynamicHeight: false,
		stopOnHover: true,
		infiniteLoop: true,
		showStatus: false,
		transitionTime: 1000,
		showThumbs: false,
		showIndicators: true,
		swipeable: true,
		emulateTouch: true,
		autoPlay: true,
	};

	const textLargeCustom = {
		fontSize: '350%'
	};

	const widhtImageSlider1 = {
		with: '400px'
	};

	const widhtImageSlider2 = {
		with: '800px'
	}


	return (
		<Carousel {...settings} className='carousel-custom p-5' >
			<div className='d-flex  flex-md-column align-items-center p-5'>

				<div className="text-start  ">
					<h2 className="ralewayBold p-3" style={textLargeCustom}>
						<span className='verde-primario-texto'>Cuida tus finanzas</span> y potencia tus recursos
					</h2>
					<br />
					<p className="ralewayRegular azul-primario-texto fs-5 px-3" >Ahorra tiempo y esfuerzo en la administración de tus recursos, solicita financiamiento y obtén múltiples beneficios</p>

					<br />
					<button type="button" className="button-mg ralewayBold">¡Unete ahora!</button>
				</div>
				<picture>
					<source  srcSet={Slide1Image} />
					<img className="carousel-img-custom-1" src={Slide1Image} style={widhtImageSlider1} alt="Imagen 1" />
				</picture>

			</div>

			<div className='d-flex  flex-md-column align-items-center p-5'>
				<div className="text-start ">
					<h2 className="ralewayBold  p-3" style={textLargeCustom}>
						Utiliza nuestra <span className='verde-primario-texto'>plataforma digital</span>
					</h2>
					<p className='ralewayRegular azul-primario-texto fs-5 px-3'>Para visualizar, gestionar y generar reportes de tus recursos financieros. </p>

					<br />
					<button type="button" className="button-mg ralewayBold">¡Unete ahora!</button>
				</div>
				<picture>
					<img className='img-fluid' src={Slide2Image} style={widhtImageSlider2} alt="Imagen 2" />
				</picture>

			</div>

		</Carousel>
	);
}
export default CarouselComponet;