import 'bootstrap/dist/css/bootstrap.css';
import iconTexts from '../assets/icon-texts.svg'
import imageCard from '../assets/image-card.png'

import { Container } from 'react-bootstrap';

function Section4Landing() {

	const justify = {
		textAlign: 'justify',
	};

	return (
		
		<section className='p-5 azul-gradient-fondo '>
			<Container className="p-5 my-5 text-center  ">
				<h3 className='ralewayBold fs-2 text-white'>En MoneyGiver estamos interesados en impulsar el crecimiento de las empresas y colaborar con sus empleados a gestionar sus finanzas personales</h3>
			</Container>
		</section>
	);
}

export default Section4Landing;