import { useEffect, useState } from "react";
import { ServiceGetAperturaCuentaContinue } from "../../services/clients/serviceClientsAccounts";

const usePedirDatosPreAlta = (idAperturaCuenta) => {
    const [datos, setDatos] = useState({
        nombreEmpresa: "",
        razonSocial: "",
        nombreRL: "",
        primerApRL: "",
        segundoApRL: "",
        correo: "",
        telefono: "",
        rutaDocumentos: "",
        idEstatus: 2,
        grupoEmpresarial: false,
        claveGrupoEmpresarial: "",
    }); // Estado para almacenar los datos obtenidos
    const [error, setError] = useState(null); // Estado para almacenar los errores
    const [loadingPreRegistarData, setLoading] = useState(false); // Estado para manejar el estado de carga

    useEffect(() => {
        if (idAperturaCuenta !== null) {
            const fetchData = async () => {
                setLoading(true); // Iniciar estado de carga
                try {
                    //console.log(idAperturaCuenta);
                    // Llamada al servicio para obtener los datos
                    const response = await ServiceGetAperturaCuentaContinue(idAperturaCuenta);

                    // Actualizar el estado con los datos obtenidos
                    setDatos(response.data); // Asumiendo que el servicio devuelve los datos en una propiedad data
                    setError(null); // Limpiar errores previos
                } catch (error) {
                    console.error("Error al obtener los datos:", error);
                    setError(error); // Establecer el error
                } finally {
                    setLoading(false); // Finalizar estado de carga
                }
            };
            fetchData();
        }



    }, [idAperturaCuenta]);

    return { datos, error, loadingPreRegistarData }; // Devuelve los datos, error y estado de carga
};

export default usePedirDatosPreAlta;
