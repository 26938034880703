import React, {useState} from 'react';
import { Modal, Button, Tooltip } from 'react-bootstrap';
import NotificationToast from '../notificacion';
const CustomModal = ({ show, onHide, title, body, buttonText, buttonVariant, responseContent, folioAperturaCuenta }) => {
  const [showA, setShowModal] = useState(false);
  const toggleShowA = () => {
		setShowModal(!showA)
	};

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title className='ralewayBold'>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h4 className='ralewayRegular'>{body}</h4>
        <br></br>

        <p className='ralewayRegular'>
          {responseContent ? (
            <>
              Aquí está su folio de grupo empresarial, respete los ceros que contenga: <span style={{fontWeight:"bolder"}} className='verde-primario-texto'>{responseContent}.</span>
              
            </>
          ) : (
            ""
          )}
        </p>
        <p className='ralewayRegular'>
          {folioAperturaCuenta ? (
            <>
              Además de su folio de apertura, por favor guarde dicho folio para futuras acciones: <span style={{fontWeight:"bolder"}} className='verde-primario-texto'>{folioAperturaCuenta}</span>
              
                <Button size="sm"onClick={() =>{
                  navigator.clipboard.writeText(`Folio de apertura: ${folioAperturaCuenta}\nFolio de grupo: ${responseContent}`);
                  setShowModal(true)
                }} variant="outline-success"><i className="bi-copy"></i>
                </Button>
              
            </>
          ) : (
            ""
          )}
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant={buttonVariant} onClick={onHide}>
          {buttonText}
        </Button>
      </Modal.Footer>
      <NotificationToast title={"Datos copiados"} message={"Los folios han sido copiados a su portapapeles"} type={"success"} show={showA} onClose={toggleShowA} />
    </Modal>
    
    
  );
};

export default CustomModal;