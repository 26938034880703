import React, { useState, useEffect } from "react";
import iconTexts from "../../assets/icon-texts.svg";
import "../../styles/nominaSection3.css";

export const Section3Prestamo = () => {
  const textSize = {
    fontSize: "14px",
  };

  return (
    <section className="">
      <h4 className="ralewayBold">Beneficios</h4>
      <div className="d-flex justify-content-start">
        <ul className="navbar-nav">
          <li className="nav-item">
            <img src={iconTexts} alt="..." />
            Pide tu préstamo sin necesidad de referencias personales ni
            garantías adicionales
          </li>
          <li className="nav-item">
            <img src={iconTexts} alt="..." />
            Olvida los trámites pesados
          </li>
          <li className="nav-item">
            <img src={iconTexts} alt="..." />
            Incluye seguro de vida y seguro de desempleo de 3 meses
          </li>
          <li v>
            <img src={iconTexts} alt="..." />
            Contrato a corto y mediano plazo y pagos fijos mensuales
          </li>
          <li className="nav-item">
            <img src={iconTexts} alt="..." />
            Acceso gratuito a la comunidad money giver mediante la app
          </li>
        </ul>
      </div>
    </section>
  );
};
