import React, { useState, useEffect } from 'react';
import '../styles/FloatingButton.css'; // Asegúrate de tener un archivo de estilo para tu botón flotante
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';

const FloatingButton = () => {
	const [isVisible, setIsVisible] = useState(false);

	const handleScroll = () => {
		// Ajusta el umbral según tus necesidades
		const threshold = 500;
		const scrolled = window.scrollY;

		setIsVisible(scrolled > threshold);
	};

	useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
			window.removeEventListener('scroll', handleScroll);
		};
	}, []);

	const scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	return (

		<ScrollLink className={`floating-button ${isVisible ? 'visible' : ''}`}
			to="home"
			smooth={true}
			duration={800}  // Ajusta la duración según tus necesidades (en milisegundos)
		>↑</ScrollLink>
	);
};

export default FloatingButton;