import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
export const Legales = () => {
	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
		  .split('_')
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  };

	  const location = useLocation();

	  useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
		
		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);
	
		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	  }, [location.pathname]);

	return (
		<div id='home'>
			<Header />
			<FloatingButton />
			<WhatsAppButton />
			<div className=' my-5 py-4 text-center '>
				<div className=' my-5 py-4 text-center '>
					<br className=''></br>
					<h2 className="ralewayBold py-5 lh-base negro-primario-texto"><strong>ADVERTENCIA</strong></h2>

					<div className='container text-center'>
						<p>MONEY GIVER, S.A.P.I. DE C.V., SOFOM, E.N.R. no requiere autorización de la Secretaría de Hacienda y Crédito Público (SHCP) para su constitución y operación, y está sujeta a la supervisión de la Comisión Nacional Bancaria y de Valores (CNBV) en materia de prevención de operaciones con recursos de procedencia ilícita y financiamiento al terrorismo en términos del artículo 56 y 95 Bis de la Ley General de Organizaciones y Actividades Auxiliares del Crédito.</p>
					</div>
					
				</div>
			</div>
			<br id='contact'></br>



			<FooterLanding />
		</div>
	);

}