import React, { useState, useEffect } from "react";
import {
    Form,
    Col,
    Button,
    Modal,
    Row,
    Image,
    ToggleButton,
    ToggleButtonGroup,
    Spinner,
    Accordion,
    InputGroup,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";



export const TengoFolio = () => {
    const [enablePreRegisterId, setEnablePreRegisterId] = useState(false);

    const [idAperturaCuenta, setIdAperturaCuenta] = useState("");
    const handleEnableIdPreRegister = (e) => {
        setEnablePreRegisterId(e.target.checked);
    };

    const handleChangeIdPreRegister = (e) => {
        setIdAperturaCuenta(e.target.value);
        //console.log(e.target.value);
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = `/formulario_merchant?ao=${idAperturaCuenta}`;
    };
    return (
        <Row>
            
            <Form onSubmit={handleSubmit}>
                <Row className="align-items-center">
                    <Col xs="5">
                        <Form.Check
                            inline
                            type="checkbox"
                            label="Ya tengo un folio"
                            name="folioAperturaCuenta"
                            value="false"
                            checked={enablePreRegisterId}
                            onChange={handleEnableIdPreRegister}
                        /></Col>
                    <Col xs="7">
                        <Form.Label htmlFor="inlineFormInputGroup" visuallyHidden>
                            Username
                        </Form.Label>
                        <InputGroup className="mb-2">
                            <Form.Control
                                disabled={!enablePreRegisterId}
                                id="inlineFormInputGroup"
                                placeholder="Folio"
                                onChange={handleChangeIdPreRegister} />
                            <Button
                                variant="outline-success"
                                id="inlineFormInputGroup"
                                disabled={!enablePreRegisterId}
                                onClick={handleSubmit}
                            >
                                Buscar
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </Row>
    )

}