import Header from '../../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../../componets/footer-landing';
import FloatingButton from '../../componets/button-go-up';
import WhatsAppButton from '../../componets/button-go-whats'
import ButtonNewEnterprise from '../../componets/button-join-moneygiver.js';
import FormContact from '../../componets/form-concat.js';
import ImageCard from '../../assets/devices/14. Arrendamiento - celular.jpg'
import ImageCard2 from '../../assets/devices/8-TARJETA IPHONE.png'
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import { Section3Prestamo } from '../../componets/prestamo/section3Prestamo.js';
import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
export const PrestamoMG = () => {

	const textSize = {
		fontSize: '14px'
	}

	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const location = useLocation();

	useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);

		// Cambiar dinámicamente el título de la página
		document.title = `Préstamo`;
	}, [location.pathname]);

	const [maxWidth, setMaxWidth] = useState("25vw");

	useEffect(() => {
	  const handleResize = () => {
		if (window.innerWidth <= 700) {
		  setMaxWidth("80vw");
		} else if (window.innerWidth <= 800) {
		  setMaxWidth("50vw");
		} 
		
		else {
		  setMaxWidth("25vw");
		}
	  };
  
	  window.addEventListener("resize", handleResize);
	  handleResize(); // Para ajustar el tamaño al cargar la página
  
	  return () => {
		window.removeEventListener("resize", handleResize);
	  };
	}, []);

	return (
		<div id='home'>
			<Header />
			<FloatingButton />
			<WhatsAppButton />

			<div className=' my-5 py-4 text-start'>
				<section className='container mb-5'>
					<div className='d-md-flex flex-lg-row flex-md-column  justify-content-between align-items-center'>
						<div className='container'>
							<h2 className='ralewayBold verde-primario-texto'>Obtén tu <span className=''>préstamo</span>
							</h2>
							<p className='ralewayRegular ' style={textSize}>Préstamos con respuesta inmediata, mediante un contrato a corto plazo. No se necesita aval ni garantías, se otorga a personas que reciben su nómina a través de MoneyGiver, con el objetivo de atender necesidades de liquidez, gastos imprevistos, viajes, pago de deudas o cualquier otra necesidad inmediata.</p>
							<div className='container py-3'>
								<img  src={ImageCard} alt='...' className='img-fluid d-md-none' />
							</div>
							{/* <ScrollLink className="button-mg-small ralewayBold text-decoration-none text-nowrap"
								to="contact"
								smooth={true}
								duration={800}  // Ajusta la duración según tus necesidades (en milisegundos)
							>Obtener préstamo</ScrollLink> */}
						</div>
						<div className='container mt-5'>
							<img src={ImageCard} alt='...' className='img-fluid d-none d-md-block' />
						</div>
					</div>
				</section>

				<section className='container mb-5'>
					<div className='d-md-flex flex-lg-row flex-md-column   justify-content-between align-items-center'>

						<div className=''>
							<img style={{maxWidth}} src={ImageCard2} alt='IMAGEN CARD' className='img-fluid d-none d-lg-block' />
						</div>
						<div className='d-flex align-items-center flex-column'>

							<p className='ralewayRegular' style={textSize}>En MoneyGiver estamos interesados en impulsar el crecimiento de las empresas y colaborar con sus empleados a gestionar sus finanzas personales.</p>
							<Section3Prestamo />
							<img style={{maxWidth}} src={ImageCard2} alt='...' className='img-fluid pt-3 d-lg-none' />
						</div>
					</div>
				</section>



			</div>


			{/* <br id='contact' />

			<h1 className='text-center ralewayBold py-5'>Regístrate <span className='verde-primario-texto'>ahora</span></h1>

			<div className='container'>
				<FormContact />
			</div> */}

			<FooterLanding />
		</div>
	);
}
