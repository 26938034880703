import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
export const Cobranza = () => {

	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
		  .split('_')
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  };

	  const location = useLocation();

	  useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
		
		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);
	
		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	  }, [location.pathname]);


	return (
		<div id="home">
			<Header />
			<FloatingButton />
			<WhatsAppButton />
			<div className=' my-5 py-4 text-center '>
			<br className='m-5'></br>
				<h2 className="ralewayBold py-5 lh-base negro-primario-texto"><strong>AVISO DE DESPACHOS DE COBRANZA</strong></h2>
				
				<div className='container text-center'>
					<p>MONEY GIVER, S.A.P.I. DE C.V., SOFOM, E.N.R. no utiliza los servicios de ninguna agencia, o despachos extrajudiciales o jurídicos, o empresa de cobranza. En caso de recibir llamadas o requerimientos de personas externas a nuestra entidad le solicitamos ponerse en contacto con nosotros. En caso de cualquier queja por favor comunicarse al 55 8421 0001, por medio de correo electrónico a atenciónaclientes@moneygiver.com.mx o acudir a nuestra oficina ubicada en Juan Salvador Agraz N° 97 Planta Baja, Col. Lomas de Santa Fe, Cuajimalpa de Morelos, CDMX, CP 05300 de lunes a viernes de 09:00 a 17:00 horas.</p>
				</div>
			</div>

			<br id='contact'></br>



			<FooterLanding />
		</div>
	)
}