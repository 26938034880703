import React, { useEffect, useState } from 'react';
import { Form, Col, Row, Button, Badge } from 'react-bootstrap';

const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

const ContactDetailsForm = ({ formData, onFormDataChange }) => {
  const [errors, setErrors] = useState({});
  const [newBroker, setNewBroker] = useState('');
  const [isFirstTimeLoaded, setFirstTimeLoaded] = useState(1);

  useEffect(() => {
    isFirstTimeLoaded === 1 ? setFirstTimeLoaded(2) : validateForm();
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};
    let valid = true;

    if (!formData.contactName.trim()) {
      newErrors.contactName = 'El nombre de contacto es requerido';
      valid = false;
    }
    if (!formData.firstLastName.trim()) {
      newErrors.firstLastName = 'El apellido paterno del contacto es requerido';
      valid = false;
    }

    if (!formData.phone.trim()) {
      newErrors.phone = 'El número de teléfono es requerido';
      valid = false;
    } else if (!/^\d{10}$/.test(formData.phone)) {
      newErrors.phone = 'El número de teléfono debe contener exactamente 10 dígitos';
      valid = false;
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'El correo electrónico es requerido';
      valid = false;
    } else if (!validateEmail(formData.email)) {
      newErrors.email = 'Formato del correo electrónico inválido, por favor verifique.';
      valid = false;
    }

    setErrors(newErrors);
    onFormDataChange(formData, valid);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'email') {
      onFormDataChange({
        ...formData,
        [name]: value,
        username: value, // Asignar el email al username
      });
    } else if (name === 'phone') {
      const newValue = value.replace(/\D/g, '').slice(0, 10); // Permitir solo números y limitar a 10 dígitos
      onFormDataChange({ ...formData, [name]: newValue });
    } else {
      onFormDataChange({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleBrokerChange = (index, e) => {
    const { name, value } = e.target;
    const updatedBrokers = formData.brokers.map((broker, i) =>
      i === index ? { ...broker, [name]: value } : broker
    );
    onFormDataChange({
      ...formData,
      brokers: updatedBrokers,
    });
  };

  const handleAddBroker = () => {
    if (newBroker.trim() !== '') {
      // Agregar el broker al arreglo y limpiar el campo de entrada
      const updatedBrokers = [...formData.brokers, { idBroker: newBroker }];
      onFormDataChange({
        ...formData,
        brokers: updatedBrokers,
      });
      setNewBroker('');
    }
  };

  const handleDeleteBroker = (index) => {
    // Eliminar el broker del arreglo
    const updatedBrokers = formData.brokers.filter((_, i) => i !== index);
    onFormDataChange({
      ...formData,
      brokers: updatedBrokers,
    });
  };

  return (
    <Form noValidate>
      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="contactName">
            <Form.Label className="ralewayRegular">Nombre de contacto <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="contactName"
              value={formData.contactName}
              onChange={handleChange}
              isInvalid={!!errors.contactName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.contactName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="firstLastName">
            <Form.Label className="ralewayRegular">Primer apellido del contacto<span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="firstLastName"
              value={formData.firstLastName}
              onChange={handleChange}
              isInvalid={!!errors.firstLastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.firstLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="secondLastName">
            <Form.Label className="ralewayRegular">Segundo apellido del contacto</Form.Label>
            <Form.Control
              type="text"
              name="secondLastName"
              value={formData.secondLastName}
              onChange={handleChange}
              isInvalid={!!errors.secondLastName}
            />
            <Form.Control.Feedback type="invalid">
              {errors.secondLastName}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="phone">
            <Form.Label className="ralewayRegular">Teléfono <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              isInvalid={!!errors.phone}
            />
            <Form.Control.Feedback type="invalid">
              {errors.phone}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>

        <Col className='my-3' md={12}>
          <Form.Group controlId="email">
            <Form.Label className="ralewayRegular">Correo electrónico <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="username">
            <Form.Label className="ralewayRegular">Nombre de usuario</Form.Label>
            <Form.Control
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              disabled
            />
          </Form.Group>
        </Col>
        
      </Row>
      <Row>
        <Col className='my-3' md={12}>
          <Form.Group controlId="idBroker">
            <Form.Label className="ralewayRegular">Folio del broker</Form.Label>
            <Form.Control
              type="text"
              name="idBroker"
              value={newBroker}
              onChange={(e) => setNewBroker(e.target.value)}
            />
            <Button variant="primary" className="mt-2" onClick={handleAddBroker}>
              Añadir Broker
            </Button>
          </Form.Group>
        </Col>
      </Row>

      <Row>
        <Col className='my-3' md={12}>
          <div>
            {formData.brokers.map((broker, index) => (
              <Badge pill bg="secondary" key={index} className="me-2 mb-2">
                {broker.idBroker} 
                <Button
                  variant="link"
                  className="text-light ms-2"
                  onClick={() => handleDeleteBroker(index)}
                  style={{ padding: 0 }}
                >
                  &times;
                </Button>
              </Badge>
            ))}
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default ContactDetailsForm;
