import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats';
import ArrendamientoMain from '../componets/arrendamiento/arrendamientoBody.js';
import BeneficiosArrendamientoContent from '../componets/arrendamiento/beneficiosArrendamiento.js';
import RegistroTituloArrendamiento from '../componets/arrendamiento/registroTituloArrendamiento.js';
import FormularioArrendamientoContent from '../componets/arrendamiento/formularioArrendamiento.js';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
export const ArrendamientoPage = () => {
	const noHorizontal = {
        overflow: 'hidden'
    };

    const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
		  .split('_')
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  };

	  const location = useLocation();

	  useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
		
		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);
	
		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	  }, [location.pathname]);

	return (
		<div id="home" style={noHorizontal}>

            <Header />
            <FloatingButton />
            <WhatsAppButton />
            
            <section>
                <div className='pt-5 my-5'>
                    <ArrendamientoMain />
                </div>
            </section>
            <section>
                <BeneficiosArrendamientoContent />
            </section>
            <section className='pt-3'>
                <RegistroTituloArrendamiento />
            </section>
            <section id='contact'>
                <FormularioArrendamientoContent />
            </section>
            <FooterLanding />
        </div>
	);
}