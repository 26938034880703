import { useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import  {FormularioMerchant}  from '../componets/formularioMerchant/formulario-merchant';

export const FormMerchant = () => {

	const  [isDev, setIsDev] = useState(false)
	const formatPageName = (pageName) => {
		// Convertir "sobre_nosotros" a "Sobre nosotros"
		return pageName
			.split('_')
			.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
			.join(' ');
	};

	const location = useLocation();

	useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';

		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);

		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;

		if(location.pathname.includes("formulario_merchant")){
			setIsDev(true);
		}

	}, [location.pathname]);


	return (
		<div id="home" className='banner-circules-mg  p-5'>
			<FormularioMerchant isDev={isDev} />
		</div>
	)
}