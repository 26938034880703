import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

const DocumentationFilesForm = ({ formData, onFormDataChange, filesForm, filesAux, setFilexAux }) => {
	const [fileErrors, setFileErrors] = useState({});
	const [selectedFiles, setSelectedFiles] = useState({});
	

	useEffect(() => {
		// Restaurar archivos seleccionados desde formData.documentos
		const restoredFiles = {};
		formData.documentos?.forEach((doc) => {
			restoredFiles[doc.idDocumento] = doc.nombreDocumento;
		});
		setSelectedFiles(restoredFiles);

		//validateForm(); // Validar cuando formData.documentos cambien
	}, []);

	const handleFileChange = (e) => {
		const { name } = e.target;
		const idDocumento = e.target.closest(".my-3").id;
		const file = e.target.files[0];

		if (!file) return;

		const validTypes = [
			"image/jpeg",
			"image/jpg",
			"image/png",
			"application/pdf",
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
		];
		const maxSize = 15 * 1024 * 1024; // 15 MB

		// Validación del tipo de archivo
		if (!validTypes.includes(file.type)) {
			setFileErrors((prevErrors) => ({
				...prevErrors,
				[idDocumento]: "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF y EXCEL.",
			}));
			onFormDataChange(false);
			return;
		}

		// Validación del tamaño del archivo
		if ((idDocumento === "1" || idDocumento === "2") && file.size > 50 * 1024 * 1024) {
			setFileErrors((prevErrors) => ({
				...prevErrors,
				[idDocumento]: "La acta constitutiva no debe superar los 50 MB.",
			}));
			onFormDataChange(false);
			return;
		}

		if (file.size > maxSize) {
			setFileErrors((prevErrors) => ({
				...prevErrors,
				[idDocumento]: "El archivo no debe superar los 15 MB.",
			}));
			onFormDataChange(false);
			return;
		}

		
		// Leer el archivo y convertirlo en base64
		const reader = new FileReader();
		reader.readAsDataURL(file);
		reader.onload = () => {
			const base64String = reader.result.split(",")[1];

			// Crear el objeto de documento con el idDocumento obtenido
			const nuevoDocumento = {
				idDocumento,
				nombreDocumento: file.name,
				docBase64: base64String,
			};

			// Verificar si el documento ya existe en formData.documentos
			let nuevosDocumentos = formData.documentos ? [...formData.documentos] : [];
			const index = nuevosDocumentos.findIndex((doc) => doc.idDocumento === idDocumento);

			if (index > -1) {
				// Si el documento ya existe, reemplazarlo
				nuevosDocumentos[index] = nuevoDocumento;
			} else {
				// Si no existe, agregarlo
				nuevosDocumentos.push(nuevoDocumento);
			}

			// Limitar el número de documentos a 10
			if (nuevosDocumentos.length > 10) {
				setFileErrors((prevErrors) => ({
					...prevErrors,
					general: "No se pueden cargar más de 10 documentos.",
				}));
				onFormDataChange(false);
				return;
			}

			setFilexAux((prevFiles) => ({
				...prevFiles,
				[idDocumento]: file,
			}));

			onFormDataChange(
				{
					...formData,
					documentos: nuevosDocumentos,
				},
				true
			);

			// Actualizar la lista de archivos seleccionados
			setSelectedFiles((prevFiles) => ({
				...prevFiles,
				[idDocumento]: file.name,
			}));

			// Limpiar los errores de archivo si los hubiera
			setFileErrors((prevErrors) => ({
				...prevErrors,
				[idDocumento]: null,
			}));
		};

		reader.onerror = (error) => {
			console.error("Error al leer el archivo:", error);
		};
	};

	const validateForm = () => {
		let isValid = true;
		const newErrors = {};

		filesForm.forEach((campo) => {
			if (campo.required && !formData.documentos?.some((doc) => doc.idDocumento === campo.idDocumento)) {
				newErrors[campo.idDocumento] = "Este campo es requerido.";
				isValid = false;
			}
		});

		setFileErrors(newErrors);
		return isValid;
	};

	return (
		<Form noValidate>
			<Row>
				{filesForm.map((campo) => (
					<Col
						id={campo.idDocumento}
						key={campo.idDocumento}
						xs={12}
						md={12}
						xl={6}
						className="my-3"
					>
						<Form.Group controlId={`formFile${campo.idDocumento}`}>
							<Form.Label className="ralewayRegular">
								{campo.nombre}{" "}
								{campo.required ? <span className="text-danger">*</span> : null}
							</Form.Label>
							{campo.description && (
								<>
									<br />
									<Form.Text>{campo.description}</Form.Text>
								</>
							)}

							{/* Mostrar el archivo seleccionado */}
							{filesAux[campo.idDocumento] ? (
								<div>
									<p>
										<strong>Archivo seleccionado:</strong> {selectedFiles[campo.idDocumento]}
									</p>
									<Form.Control
										type="file"
										name={`file${campo.idDocumento}`}
										onChange={handleFileChange}
										isInvalid={!!fileErrors[campo.idDocumento]}
										isValid={(filesAux[campo.idDocumento] && !fileErrors[campo.idDocumento])}
									/>
									<Form.Control.Feedback type="invalid">
										{fileErrors[campo.idDocumento]}
									</Form.Control.Feedback>
								</div>
							) : (
								<>
									<Form.Control
										type="file"
										name={`file${campo.idDocumento}`}
										onChange={handleFileChange}
										isInvalid={!!fileErrors[campo.idDocumento]}
										isValid={(filesAux[campo.idDocumento])}
									/>
									<Form.Control.Feedback type="invalid">
										{fileErrors[campo.idDocumento] || "Este campo es requerido."}
									</Form.Control.Feedback>
								</>

							)}


						</Form.Group>
					</Col>
				))}
			</Row>
		</Form>
	);
};

export default DocumentationFilesForm;
