import React, { useState, useEffect } from "react";
import {
  Form,
  Col,
  Button,
  Modal,
  Row,
  Image,
  ToggleButton,
  ToggleButtonGroup,
} from "react-bootstrap";
import EBanking from "../../assets/logo_moneygiver_ebanking.png";
import {
  ServiceUpdateDocument,
  ServiceGetDocumentsMissing,
  ServiceGetDocumentsApertureAccount,
} from "../../services/clients/serviceClientsAccounts.jsx";
import { ApiPostApertureClient } from "../../services/clients/ClientsAccounts.jsx";
import CustomModal from "../shared/Modal.jsx";
import { API_BASE_URL } from "../../helpers/GlobalVariables.jsx";
import BackButton from "../shared/ReturnButton.jsx";
import {
  getDocumentTypeById,
  getDocumentDescriptionById,
} from "../../helpers/GetDocuments.tsx";
import NotificationToast from "../notificacion.js";

const FormularioSubirArchivos = () => {
  const [campos, setCampos] = useState([{}]);
  const [formData, setFormData] = useState({
    documentos: [],
  });
  const [fileErrors, setFileErrors] = useState([]);
  const [showA, setShowModalA] = useState(false);
  const previousPage = "formulario_merchant";
  const [validated, setValidated] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [formKey, setFormKey] = useState(0);
  const [errors, setErrors] = useState({});
  const [modalTitle, setModalTitle] = useState("");
  const [modalBody, setModalBody] = useState("");
  const [modalButtonVariant, setModalButtonVariant] = useState("");
  const [modalButtonText, setModalButtonText] = useState("");
  const [modalMessageResponse, setModalMessageResponse] = useState("");
  const [titleToast, setTitleToast] = useState("");
  const [messageToast, setMessageToast] = useState("");
  const [typeToast, setTypeToast] = useState("");
  const queryParams = new URLSearchParams(window.location.search);
  const idPreRegistro = queryParams.get("ao");
  const fetchDocuments = async () => {
    try {
      // Obtener el query string de la UR
      const documentosFaltantes = await ServiceGetDocumentsApertureAccount();

      const nuevosArchivos = documentosFaltantes.map((documento) => ({
        idDocumento: documento.idDocumento,
        nombre: getDocumentTypeById(documento.idDocumento),
        tipo: "file",
        required: documento.requerido,
        description: getDocumentDescriptionById(documento.idDocumento),
      }));
      setCampos(nuevosArchivos);
    } catch (error) {
      console.error("Error fetching documents:", error);
      // Manejo de errores, puedes agregar más lógica de manejo de errores aquí, como mostrar un mensaje de error en la UI
    }
  };

  useEffect(() => {
    fetchDocuments();
  }, []);

  // Función para mostrar el modal
  const handleShowModal = (
    title,
    body,
    buttonText,
    buttonVariant,
    folioGrupoEmpresarial
  ) => {
    setModalTitle(title);
    setModalBody(body);
    setModalButtonText(buttonText);
    setModalButtonVariant(buttonVariant);
    setModalMessageResponse(folioGrupoEmpresarial);
    setShowModal(true);
  };

  const handleToast = (title, message, type) => {
    setTitleToast(title);
    setMessageToast(message);
    setTypeToast(type);
    setShowModalA(true);
  };

  // Función para ocultar el modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  // Ejemplo de cómo utilizar el modal de éxito
  const handleSuccessModal = (message) => {
    handleShowModal("Formulario Enviado", message, "Cerrar", "primary");
  };

  // Ejemplo de cómo utilizar el modal de error
  const handleErrorModal = (message) => {
    handleShowModal("Error", message, "Cerrar", "danger");
  };

  const toggleShowA = () => {
    setShowModalA(!showA);
  };

  const handleChange = (e) => {
    const { name = "", type, value } = e.target;
    setFormData({ ...formData, [name]: value });

    // Manejar validaciones según el nombre del campo
    let newErrors = { ...errors };

    if (name.toUpperCase().includes("TELEFONO")) {
      if (!value.trim()) {
        newErrors[name] = "Este campo es requerido.";
      } else if (!/^[0-9]{10}$/.test(value)) {
        newErrors[name] = "El teléfono debe contener 10 dígitos numéricos.";
      } else {
        delete newErrors[name];
      }
    } else if (name.toUpperCase().includes("RFC")) {
      const rfcRegex = /^[A-Z&Ña-zñ]{3,4}[0-9]{6}[0-9A-Za-z]{3}$/i;
      
      if (!value.trim()) {
        newErrors[name] = "Este campo es requerido.";
      } else if (!rfcRegex.test(value.toUpperCase())) {
        newErrors[name] = "RFC inválido. Verifica el formato.";
      } else {
        delete newErrors[name];
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
    setErrors(newErrors);
    if (type === "file") {
      const idDocumento = e.target.closest(".my-3").id;
      const file = e.target.files[0];
      const validTypes = [
        "image/jpeg",
        "image/jpg",
        "image/png",
        "application/pdf",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ];
      const maxSize = 15 * 1024 * 1024; // 15 MB

      if (file) {
        if (!validTypes.includes(file.type)) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]:
              "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF Y EXCEL.",
          }));
          handleToast(
            "Error en el archivo",
            "Tipo de archivo no válido. Solo se permiten JPG, JPEG, PNG, PDF y EXCEL.",
            "error"
          );
          e.target.setCustomValidity("Invalid");
          return;
        }

        if (file.size > maxSize) {
          setFileErrors((prevErrors) => ({
            ...prevErrors,
            [idDocumento]: "El archivo no debe superar los 15 MB.",
          }));
          handleToast(
            "Error en el archivo",
            "El archivo no debe superar los 15 MB.",
            "error"
          );
          e.target.setCustomValidity("Invalid");
          return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onload = () => {
          const base64String = reader.result.split(",")[1];

          const nuevoDocumento = {
            idDocumento: idDocumento,
            nombreDocumento: file.name,
            docBase64: base64String,
          };

          setFormData((prevData) => ({
            ...prevData,
            documentos: [...prevData.documentos, nuevoDocumento],
          }));
        };
        reader.onerror = (error) => {
          console.error("Error al leer el archivo:", error);
        };
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity()) {
      try {
        
        const request = {
          documentos: formData["documentos"],
          idAperturaCuenta: idPreRegistro,
        };
        
        const response = await ServiceUpdateDocument(
          request
        );
        
        // Manejo de la respuesta
        if (response?.success || response?.httpStatusCode === 200) {
          setFormKey((prevKey) => prevKey + 1);
          setFormData({});
          handleSuccessModal(response?.message);
        } else {
          handleErrorModal(response?.message);
        }
      } catch (error) {
        console.error("Error en la actualización de los documento:", error);
        handleErrorModal();
      }
    }
  };

  return (
    <div className="container  p-5 d-flex flex-column align-items-center justify-content-center ">
      <Image src={EBanking} className="img-fluid pb-3" />
      <BackButton targetPage={previousPage} />
      <h5 className="text-center ralewayBold my-3">
        Para continuar con su alta a Money Giver, necesita proporcionar los
        siguientes documentos:
      </h5>
      <Form
        key={formKey}
        noValidate
        validated={validated}
        onSubmit={handleSubmit}
      >
        <Row className="row">
          {campos.map((campo, index) => (
            <Col
              xs={12}
              md={12}
              xl={12}
              className="my-3"
              id={campo.idDocumento}
              key={campo.idDocumento}
            >
              {campo.tipo === "file" ? (
                <Form.Group controlId={campo.idDocumento} className="">
                  <Form.Label className="ralewayRegular">
                    {campo.nombre}{" "}
                    {campo.required ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </Form.Label>
                  {!campo.description ? null : (
                    <>
                      <br /> <Form.Text>{campo.description}</Form.Text>
                    </>
                  )}
                  <Form.Control
                    type="file"
                    name={campo.idDocumento}
                    onChange={handleChange}
                    required={campo.required ?? false}
                  />
                  <Form.Control.Feedback type="invalid">
                    {fileErrors[campo.idDocumento] ||
                      "Este campo es requerido."}
                  </Form.Control.Feedback>
                </Form.Group>
              ) : (
                <Form.Group controlId={campo.nombre}>
                  <Form.Label className="ralewayRegular">
                    {campo.nombre}

                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type={campo.tipo}
                    name={campo.nombre}
                    onChange={handleChange}
                    required={campo.required ?? false}
                    isInvalid={!!errors[campo.nombre]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors[campo.nombre] || "Este campo es requerido."}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            </Col>
          ))}
        </Row>
        <Col md={4}>
          <button
            disabled={errors.length > 0}
            type="submit"
            className="ralewayBold button-mg-blue"
          >
            Enviar
          </button>
        </Col>
      </Form>
      <NotificationToast
        title={titleToast}
        message={messageToast}
        type={typeToast}
        show={showA}
        onClose={toggleShowA}
      ></NotificationToast>
      {/* Modal reutilizable */}
      <CustomModal
        show={showModal}
        onHide={handleCloseModal}
        title={modalTitle}
        body={modalBody}
        buttonText={modalButtonText}
        buttonVariant={modalButtonVariant}
        responseContent={modalMessageResponse}
      />
    </div>
  );
};

export default FormularioSubirArchivos;
