import Header from '../header.js'
import 'bootstrap/dist/css/bootstrap.css';
import FooterLanding from '../componets/footer-landing';
import FloatingButton from '../componets/button-go-up';
import WhatsAppButton from '../componets/button-go-whats';
import FormContact from '../componets/form-concat.js';
import { useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
export const Privacidad = () => {
	const formatPageName = (pageName) => {
		return pageName
		  .split('_')
		  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		  .join(' ');
	  };

	  const justifyText = {
		textAlign:"justify"
	  }

	  const location = useLocation();

	  useEffect(() => {
		// Extraer el nombre de la página de la ruta
		const pageName = location.pathname.split('/').filter(Boolean).pop() || 'Inicio';
		
		// Formatear el nombre de la página
		const formattedPageName = formatPageName(pageName);
	
		// Cambiar dinámicamente el título de la página
		document.title = `${formattedPageName}`;
	  }, [location.pathname]);
	return (
		
		<div id="home">
			<Header />
			<FloatingButton />
			<WhatsAppButton />
			<div  className=' my-5 py-4 text-center'>
				<h3 className="ralewayBold py-5 negro-primario-texto"><strong>AVISO DE PRIVACIDAD</strong></h3>
				<div className='text-start container'>
					<p style={justifyText}><strong>Money Giver, S.A.P.I. de C.V., S.O.F.O.M., E.N.R.</strong>, (en lo sucesivo MONEY GIVER), con domicilio en Avenida Cd. Universitaria 286, 1er Piso, Colonia Jardines del Pedregal, Alcaldía Álvaro Obregón, C.P. 01900, CDMX, y portal de internet <strong>www.moneygiver.com.mx</strong>, es responsable de la confidencialidad, uso y protección de los datos personales que los usuarios llegasen a proporcionarle por los distintos medios que utiliza MONEY GIVER para prestar sus servicios y por lo cual garantiza que su información personal se encuentra protegida de conformidad con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDP).</p>

					<b>I.	Uso y finalidad de datos personales.</b>
					<p>Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que son necesarias para contratar el producto y/o servicio que solicite:</p>
					
					<p><b>1. Finalidades principales</b></p>
					<p style={justifyText} className='sangria'>1.1. Generar datos de autenticación para, por un lado, crear un perfil de acceso a los sistemas electrónicos para prestarle atención personalizada y, por el otro, acreditar su identidad cuando utilice equipos o sistemas electrónicos y/o automatizados para la prestación de nuestros servicios; si se encuentra en nuestras instalaciones, para mantener el control de acceso y mantenimiento de programas para garantizar la seguridad y protección de clientes, empleados, visitantes y proveedores por medio de dispositivos y medios electrónicos, ópticos o por cualquier otra tecnología;</p>
					<p style={justifyText} className='sangria'>1.2.	Acreditar su identidad conforme a los procedimientos internos de MONEY GIVER, incluyendo el solicitarle información de carácter crediticio para cotejar y/o validar dicha información, o incluso información demográfica, como su domicilio, con la sociedad de información crediticia de nuestra preferencia;</p>
					<p style={justifyText} className='sangria'>1.3. La realización de todas las actividades relacionadas con operaciones de crédito, nómina, tarjetas o similares, que pueden incluir: el procesamiento de solicitudes y la administración, la emisión, envío y activación de tarjetas; actividades de cobranza, incluyendo el envío de comunicaciones a través de correos electrónicos, llamadas telefónicas, servicios de mensajería instantánea, mensajes cortos y demás medios de comunicación físicos y/o electrónicos; aclaración, investigación y facturación de cargos relacionados con tarjetas; realizar investigaciones y monitoreo periódicos sobre su comportamiento crediticio; realizar visitas domiciliarias para validaciones de datos, entrega de tarjetas y/o para la gestión de solicitudes; creación de perfiles de consumo, promedios de gasto e inscripciones, acumulación de puntos, redenciones y administración de programas de lealtad tanto propios como de terceros;</p>
					<p style={justifyText} className='sangria'>1.4. Elaborar informes estadísticos y financieros para su empresa o la empresa en la que colabora;</p>
					<p style={justifyText} className='sangria'>1.5. La creación e implementación de procesos analíticos y estadísticos necesarios para la prestación de los servicios;</p>
					<p style={justifyText} className='sangria'>1.6. Contactarlo para cualquier tema relacionado a los servicios o al presente Aviso de Privacidad;</p>
					<p style={justifyText} className='sangria'>1.7. Cumplir obligaciones derivadas de cualquier relación jurídica que se genere entre MONEY GIVER y usted por la prestación de los servicios;</p>
					<p style={justifyText} className='sangria'>1.8. La originación, operación, gestión, procesamiento y administración de los productos y/o servicios contratados con MONEY GIVER, estando esta última facultada para: (i) realizar la verificación/validación de los datos personales proporcionados incluyendo la veracidad de los mismos directamente con autoridades gubernamentales y/o contra bases de datos de entidades públicas y (ii) en caso de discrepancia, llevar a cabo las rectificaciones o correcciones que resulten necesarias para mantener actualizada y correcta su información;</p>
					<p style={justifyText} className='sangria'>1.9. Para fines de auditorías internas o externas, así como para cumplir con las obligaciones de colaboración y/o requerimientos de información formulados por las autoridades gubernamentales competentes, cuando sea necesario para salvaguardar el interés público, la procuración o administración de justicia y/o en términos de la legislación vigente y aplicable.</p>
					<p style={justifyText} className='sangria'>1.10. Para integrar expedientes y bases de datos, y dar tratamiento a los mismos, ya sea directamente por MONEY GIVER o a través de terceros o proveedores necesarios para que MONEY GIVER pueda prestar los servicios propios de su objeto y reportar a las autoridades administrativas y jurisdiccionales dicha información cuando asílo establezcan las disposiciones legales vigentes;</p>

					<p><b>2. Finalidades secundarias</b></p>

					<p style={justifyText}>De manera adicional, utilizaremos su información personal para las siguientes finalidades secundarias que <b>no son necesarias</b> para el servicio solicitado, pero que nos permiten y facilitan brindarle una mejor atención:</p>

					<p style={justifyText} className='sangria'>2.1. Hacer consultas, encuestas, investigaciones y revisiones en relación a sus quejas o reclamaciones;</p>

					<p style={justifyText} className='sangria'>2.2. Evaluar la calidad de los servicios mediante encuestas;</p>
 
					<p style={justifyText} className='sangria'>2.3. Envío por parte de MONEY GIVER, de toda clase de avisos, notificaciones, comunicaciones, encuestas de servicio e información adicional relacionada con los productos y servicios contratados y monitoreo mediante llamadas telefónicas, correoselectrónicos, mensajes de texto y/o cualquier otro medio de contacto, respecto de cualquier actividad que se relacione directa o indirectamente con los productos y/o servicios que ofrece MONEY GIVER;</p>

					<p style={justifyText} className='sangria'>2.4. Monitoreo y grabación de las sesiones del Usuario dentro de la o las plataformas tecnológicas utilizadas por MONEY GIVER para la prestación de los servicios, con la finalidad de medir la calidad de los servicios, investigación respecto al uso de la tecnología desarrollada por MONEY GIVER, entendimiento de hábitos transaccionales del cliente;</p>

					<p style={justifyText} className='sangria'>2.5. Mercadotecnia o publicitaria;</p>

					<p style={justifyText} className='sangria'>2.6. Prospección comercial.</p>

					<p style={justifyText}>La negativa para el uso de sus datos personales para estas finalidades secundarias no podráser un motivo para que le neguemos los servicios y productos que solicita o contrata con nosotros.</p>

					<p style={justifyText}>Toda la información personal proporcionada será utilizada únicamente para fines de identificación en cualquier tipo de relación jurídica o de negocios que realice con MONEY GIVER, incluyendo la contratación de los servicios que se proveen a través del sitio web, creación de cuenta con nombre de usuario y contraseña para contratar los servicios, ofrecerle promociones, productos o servicios, contactarlo para ofrecerle nuestros servicios, atender quejas y aclaraciones para fines mercadológicos y de prospección comercial, responder a los requerimientos de información, atención y servicios; evaluar la calidad del servicio brindado y en su caso tratarlos para fines compatibles con los mencionados en este aviso; gestión financiera, facturación y cobro, asícomo cualquier otra actividad tendiente a promover, manejar y mantener los servicios brindados y, en caso de formalizar una relación jurídica o de negocios con MONEY GIVER, ser para dar cumplimiento a las obligaciones y compromisos pactados entre ambos.</p>
					
					<p><b>II. Datos personales a recabar</b></p>
					

					<p style={justifyText}>Para las finalidades a las que se alude en el numeral I que antecede, MONEY GIVER podrá recabar los siguientes datos personales:</p>

					<ul style={justifyText}>
						<li >Información de contacto (Nombre, Email, Dirección, Teléfono, Celular).</li>
						<li>Información personal (Nombre, Fecha de nacimiento, país de nacionalidad, CURP).</li>
						<li>Razón social o denominación en caso de ser persona moral, nombre del representante legal, acta constitutiva, poderes protocolizados, actas de asamblea, libros corporativos, comprobantes de domicilio.</li>
						<li>Datos Biométricos (Propiedades físicas, fisiológicas, de comportamiento o rasgos de la personalidad atribuibles a una sola persona y que son medibles tales como la huella digital o fotografías del rostro).</li>
						<li>Actividad económica.</li>
						<li>Documentación de identidad (Credencial de Elector, Pasaporte, Cédula Profesional, FM2 y FM3).</li>
						<li>Información financiera y medios de pago (Tarjeta de crédito, débito, cheques).</li>
						<li>Documentación financiera (estados de cuenta bancarios, estados financieros, declaración de impuestos, constancia de situación fiscal).</li>
						<li>Información Fiscal (RFC, Dirección de Facturación).</li>
						<li>Credenciales de acceso a servicios en línea (Usuario y contraseña).</li>
						<li>Identificación en Redes Sociales (Facebook, Twitter, LinkedIn, Instagram, Snapchat y cualquier otra conocida o por conocer).</li>
						<li>Situación patrimonial (Ingresos, bienes inmuebles, inversiones u otro tipo de cuentas de banco).</li>
						<li>Información familiar (Cónyuge, hijos, nietos).</li>
						<li>Referencias personales (Información de personas no familiares).</li>
						<li>Información sobre comportamiento crediticio en Buró o Círculo de Crédito.</li>
						<li>Información sobre el préstamo (Destino, documentos de soporte, monto y plazos requeridos).</li>
						<li>Información laboral (Patrón, número de seguridad social, salario).</li>
						<li>Referencias personales o comerciales (nombre de la persona o empresa, persona de contacto, cargo de la persona de contacto, teléfono, correo electrónico).</li>
						<li>Medio de recomendación (¿cómo te enteraste de nosotros?).</li>

					</ul>

					<p style={justifyText}>Al ingresar sus datos en los formatos físicos, a través de la página web o mediante aplicación de dispositivo móvil, el usuario manifiesta su consentimiento expreso para el tratamiento de dichos datos a MONEY GIVER.</p>

					<p style={justifyText}>El ingreso y/o registro a través del sitio web, implica el consentimiento pleno y sin reservas de los usuarios para el tratamiento de sus datos personales de acuerdo con el presente Aviso de Privacidad.</p>

					<p><b>III. Recopilación de datos personales</b></p>

					<p style={justifyText}>La recopilación de datos se llevará a cabo única y exclusivamente a través de los formularios impresos, formularios de nuestro sitio de internet, por medio de aplicaciones de dispositivos móviles <i>Android</i> o <i>iOS</i>, por otros medios electrónicos o vía telefónica; además de obtener información a través de otras fuentes de acceso público que están permitidas por la ley como directorios telefónicos, laborales, sitios web, etc. En caso de no contar con los datos señalados MONEY GIVER no estaría en posibilidad de proporcionar su servicio, ya que los datos que se solicitan son indispensables para brindar los servicios de MONEY GIVER.</p>

					<p><b>IV. Derechos ARCO</b></p>

					<p style={justifyText}>Usted tiene derecho a conocer quédatos personales tenemos de usted, para quélos utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho solicitar la corrección de su información personal en caso de que esté desactualizada, sea inexacta o incompleta (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la misma no estásiendo utilizada adecuadamente (Cancelación); asícomo oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO.</p>

					<p style={justifyText}>Para el caso que se desee, limitar el uso o divulgación de su información personal, ejercitar sus derechos ARCO de Acceder (A) y conocer los datos personales que posee MONEY GIVER y los detalles del tratamiento y uso de los mismos, Rectificar (R) y corregirlos en caso de ser inexactos o incompletos, Cancelar (C) y eliminar sus datos personales cuando considere que no se requieren para alguna de las finalidades no autorizadas o haya finalizado la relación jurídica y/o de servicios, asícomo Oponerse (O) al tratamiento de los datos personales para fines específicos y revocar el consentimiento que para los fines anteriores le ha sido otorgado a MONEY GIVER, lo podráhacer a través de los siguientes canales de atención:</p>

					<p style={justifyText}>Centro de atención telefónica al número: 5585 25 36 37 o bien 55 8421 0001 ext. 154 <br/> 
					<span style={justifyText}>Correo electrónico: <a href="mailto:atencionaclientes@moneygiver.com.mx">atencionaclientes@moneygiver.com.mx</a>;</span></p> 
					

					<p style={justifyText}>Su petición deberá ir acompañada de los argumentos por los que desea ejercer cualquiera de sus derechos, junto con una identificación oficial del titular de los datos o de su apoderado, su solicitud seráatendida en un plazo no mayor a 20 días hábiles.</p>

					<p style={justifyText}>MONEY GIVER se reserva el derecho de verificar la veracidad de la información antes de realizar o autorizar el cambio de los datos personales. Si considera que su derecho cuenta con evidencia de que en el tratamiento de sus datos personales existe alguna violación a las disposiciones previstas en la LFPDP, le invitamos a ponerse en contacto nuevamente con nosotros para agotar todos los procedimientos internos a efecto de satisfacer plenamente su solicitud. De no ser el caso, el usuario podrá interponer la queja correspondiente ante el Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI).</p>

					<p style={justifyText}>MONEY GIVER podrá mantener información sobre la actividad del usuario en el portal para fines estadísticos, sin que esto suponga la retención de datos de identificación, documentos, o información sensible. MONEY GIVER se reserva la posibilidad de mantener datos requeridos para ejercer sus políticas de negocio, como evitar solicitantes de crédito completen una nueva solicitud en menos de 6 meses si la solicitud original ha sido rechazada.</p>

					<p><b>V. Seguridad y confidencialidad</b></p>

					<p style={justifyText}>MONEY GIVER cuenta con medidas físicas, técnicas y administrativas de seguridad para la protección de Datos Personales proporcionados por los usuarios.</p>

					<p style={justifyText}>Le informamos que sus datos personales no serán transferidos a terceros para fines distintos a los necesarios para brindarle oportunamente los servicios que se contraten con MONEY GIVER, salvaguardando su protección y confidencialidad, sin que para ello sea necesario obtener su autorización en términos del artículo 37 de la LFPDP. La seguridad y confidencialidad de los datos que los usuarios proporcionen al contratar un servicio estarán protegido por un servidor seguro, de tal forma que los datos enviados se transmitirán encriptados para asegurar su resguardo.</p>

					<p style={justifyText}>El almacenamiento local y las <i>cookies</i> son pequeñas piezas de información que son enviadas por el sitio web a su navegador, nos permiten reconocerlo al momento de ingresar a nuestro sitio web, detectar el ancho de banda que ha seleccionado para ingresar al <i>home page</i> de nuestra página web y saber, que información es aconsejable destacar, calcular el tamaño de audiencia y medir algunos parámetros de tráfico. En el caso de <i>cookies</i>, el botón de “ayuda” que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirácomo evitar aceptar nuevas <i>cookies</i>, como hacer que el navegador le notifique cuando recibe una nueva <i>cookie</i> o cómo deshabilitar todas las <i>cookies</i>.</p>


					<p><b>VI. Transferencia de datos personales</b></p>

					<p style={justifyText}>A continuación le comunicamos que las siguientes transferencias no requieren del consentimiento del usuario, pero la ley exige que le sean informadas. En este sentido, sus datos personales podrán ser compartidos a:</p>
					<ul style={justifyText}>

						<li>Sociedades del mismo grupo del responsable que opere bajo los mismos procesos y políticas internas. (Sociedades controladoras, subsidiarias, afiliadas, franquicias), para dar cumplimiento a la relación jurídica adquirida con el Titular.</li>
						<li>Entidades del sector público, para dar cumplimiento a la legislación vigente, así como a la relación jurídica con el Titular.</li>
						<li>Entidades públicas dedicadas a la prestación de diagnóstico médico, prestación de asistencia sanitaria, tratamiento médico o gestión de servicios sanitarios (Hospitales, médicos, laboratorios), para llevar a cabo todas las gestiones necesarias en caso de emergencia médica.</li>
						<li>Buró de Crédito u otras instituciones que gestionan información de historial crediticio acerca de incumplimientos a pagos en caso de ser un Solicitante de préstamos.</li>
						<li>Proveedores externos de servicios bancarios y de medios de pago por internet, necesarios para realizar una transferencia de dinero requerida por Usted.</li>
					</ul>
					<p><b>VII. Modificaciones al aviso de privacidad</b></p>
					<p style={justifyText}>MONEY GIVER se reserva el derecho de efectuar en cualquier momento modificaciones al presente Aviso, para la atención de novedades legislativas, políticas internas o nuevos requerimientos para cumplir con las finalidades previstas. Cualquier cambio o modificación que se realice al presente Aviso, podráverificarse en la página <a target="_blank" rel="noopener noreferrer" href="https://www.moneygiver.com.mx">www.moneygiver.com.mx</a></p>

					<p style={justifyText}>Una vez que se publique el Aviso de Privacidad en el sitio web, entrará en vigor automáticamente y deberá́darse estricto cumplimiento al texto.</p>

					<p style={justifyText}>Este Aviso de Privacidad contempla en todo momento los principios de licitud, consentimiento, información, calidad, finalidad, lealtad, proporcionalidad y responsabilidad en el tratamiento de datos personales.</p>

					<p style={justifyText}>Esta declaración de Privacidad está sujeta a los términos y condiciones antes descritas. Si el usuario utiliza nuestros servicios, significa que ha leído, entendido y acordado los términos antes expuestos. Si el usuario considera que han sido vulnerados sus derechos respecto de la protección de datos personales, tiene el derecho de acudir al Instituto Nacional de Transparencia, Acceso a la Información y Protección de Datos Personales (INAI) como autoridad competente para defender su ejercicio.</p>
					<br/>
					<p style={{textAlign:"right"}}>Fecha de actualización 30 de septiembre de 2022.</p>
				</div>

			</div>

			<br id='contact'></br>


			
			<FooterLanding />
		</div>
	)
}