import React from 'react';

import EBanking from '../../assets/logo_moneygiver_ebanking.png';
import FormularioSubirArchivos from '../formularioDinamico/FormularioSubirArchivos';

export const FormularioPreAltaSubirArchivos = () => {
	return (
		<div className="container  p-5 d-flex flex-column align-items-center justify-content-center ">
            <FormularioSubirArchivos></FormularioSubirArchivos>
		</div>
	);
};

