import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import splash from '../../assets/icon-texts.svg';
import '../../App.css';

function BeneficiosArrendamientoContent() {
    const tile = {
        fontFamily: 'RalewayRegular',
        fontSize: 14,
        fontWeight: 400,
        whiteSpace: "pre-wrap",
    };

    const subtitle = {
        fontFamily: 'RalewayRegular',
        fontSize: "18pt",
        fontWeight: 400,
    };

    const float = {
        float: "left",
    };

    return (
        <Container >
            <div >
                <Row >
                    <span style={subtitle}><strong>Beneficios arrendamiento puro:</strong></span>
                </Row>
                <br className='mx-1'></br>
                <Row >
                    <Col >
                        <span style={tile}>
                            <Image src={splash} style={float} />
                            Renta deducible
                        </span>
                    </Col>
                </Row>
                <br className='mx-1'></br>
                <Row>
                    <Col>
                        <span style={tile}>
                            <Image src={splash} style={float} />
                            No causa deuda (pasivo)
                        </span>
                    </Col>
                </Row>
                <br className='mx-1'></br>
                <Row>
                    <Col>
                        <span style={tile}>
                            <Image src={splash} style={float} />
                            No se deprecia el activo
                        </span>
                    </Col>
                </Row>
                <br className='mx-1'></br>
                <Row>
                    <Col>
                        <span style={tile}>
                            <Image src={splash} style={float} />
                            Evita la descapitalización
                        </span>
                    </Col>
                </Row>
                <br className='mx-1'></br>
                <Row>
                    <Col>
                        <span style={tile}>
                            <Image src={splash} style={float} />
                            Disponibilidad inmediata
                        </span>
                    </Col>
                </Row>
                <br className='mx-1'></br>
            </div>

        </Container>
    );
}

export default BeneficiosArrendamientoContent;