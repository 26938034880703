import 'bootstrap/dist/css/bootstrap.css';
import FormContact from './form-concat';
import contactImage from '../assets/contact-image.jpg'
import React from "react";
import ParallaxConcact from './parallax-img';
import ImagenCelular from "../assets/devices/6-MANO IPHONE RECTANGULO.png"

function ConcatLanding() {

	/*const handleScroll = () => {
		// Calcula la posición del scroll y ajusta el valor de background-position
		const scrollPosition = window.scrollY;
		const backgroundPosition = `50% ${scrollPosition * 0.5}px`; // Ajusta la velocidad del parallax aquí
		document.getElementById('parallax-section').style.backgroundPosition = backgroundPosition;
	  };
	  React.useEffect(() => {
		window.addEventListener('scroll', handleScroll);
		return () => {
		  window.removeEventListener('scroll', handleScroll);
		};
	  }, []);*/

	return (
		<section className='mt-3' id="contact">
			<div className='container text-center'>
				<div className="row align-items-center">
					<div className='col-sm-5'>
						<ParallaxConcact />
					</div>
					<div className="col-sm-6 text-start ">
						<h2 className='ralewayBold verde-primario-texto'>¡Estamos aquí para ayudarte!</h2>
						<p className='ralewayRegular my-3 lh-base fs-5'>Si estás interesado en explorar cómo podemos trabajar juntos para alcanzar estos objetivos, no dudes en enviarnos un mensaje.</p>
						<br className='m-1'/>
						<FormContact />
					</div>
					

				</div>
			</div>
		</section>
	);
}

export default ConcatLanding;