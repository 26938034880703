import Header from "../header.js";
import "bootstrap/dist/css/bootstrap.css";
import FooterLanding from "../componets/footer-landing";
import FloatingButton from "../componets/button-go-up";
import WhatsAppButton from "../componets/button-go-whats";
import ButtonNewEnterprise from "../componets/button-join-moneygiver.js";
import FormContact from "../componets/form-concat.js";
import ImageCard from "../assets/devices/macbook_test.png";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { Section2 } from "../componets/nomina/section2.js";
import { Section3 } from "../componets/nomina/section3.js";

import { Container, Row, Col } from "react-bootstrap";

export const NominaPage = () => {
  const [maxWidth, setMaxWidth] = useState("100%");
  const formatPageName = (pageName) => {
    // Convertir "sobre_nosotros" a "Sobre nosotros"
    return pageName
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const location = useLocation();

  useEffect(() => {
    // Extraer el nombre de la página de la ruta
    const pageName =
      location.pathname.split("/").filter(Boolean).pop() || "Inicio";

    // Formatear el nombre de la página
    const formattedPageName = formatPageName(pageName);

    // Cambiar dinámicamente el título de la página
    document.title = `${formattedPageName}`;
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 700) {
        setMaxWidth("80vw");
      } else {
        setMaxWidth("100%");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Para ajustar el tamaño al cargar la página

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div id="home">
      <Header />
      <FloatingButton />
      <WhatsAppButton />
      <div className=" my-5 py-4 text-start">
        <section className="container mb-5">
          <Row className="  align-items-center">
            <Col md={4}>
              <h2 className="ralewayBold">
                <span className="verde-primario-texto">
                  Somos expertos en servicios financieros con herramientas
                  innovadoras para empresas de cualquier tamaño.
                </span>
              </h2>
              <p className="ralewayRegular">
                Optimiza la gestión de nómina de tu empresa con la plataforma
                web de MoneyGiver.
              </p>
            </Col>
            <Col md={8}>
              <img
                style={{ maxWidth: maxWidth }}
                src={ImageCard}
                alt="IMAGEN CARD"
                className="img-fluid"
              />
            </Col>
          </Row>
        </section>

        <Section2 />

        <Section3 />

        <br id="contact" />

        {/* <h1 className='text-center ralewayBold py-5'>Regístrate <span className='verde-primario-texto'>ahora</span></h1> */}

        {/* <div  className='container'>
					<FormContact />
				</div> */}
      </div>

      <FooterLanding />
    </div>
  );
};
