import React, { useState, useEffect } from 'react';
import '../styles/Parallax.css'; // Asegúrate de importar o definir tus estilos CSS
import ContactImage from '../assets/devices/6-MANO IPHONE CIRCULO.png'
import { Parallax } from 'react-parallax';

const ParallaxConcact = () => {

  

    return (
        <Parallax bgImage={ContactImage} className="parallax-content" strength={0}>
            <div className='parallax-width'> 
                
                
            </div>
        </Parallax>
    );
};

export default ParallaxConcact;