// AperturaCuentaRequest.js

export const AperturaCuentaRequest = {
	AperturaCuentaEmpresa: {
	  idAperturaCuenta: "", // string
	  nombreEmpresa: "", // string
	  razonSocial: "", // string
	  calle: "", // string
	  ciudad: "", // string
	  codPostal: "", // string
	  colonia: "", // string
	  estado: "", // string
	  numExterior: "", // string
	  numInterior: "", // string
	  pais: "", // string
	  tiempoResidenciaAnios: 1, // integer ($int32)
	  esGrupoEmpresarial: false, // boolean
	  rutaDocumentos: "", // string
	  idEstatus: 1, // integer ($int32)
	  fechaApertura: "", // string ($date-time)
	  claveGrupoEmpresarial: "", // string
	  fechaModificacion: "" // string ($date-time)
	},
	AperturaCuentaRepresentante: {
	  idAperturaCuentaRepresentante: 0, // integer ($int32)
	  idAperturaCuenta: "", // string
	  nombreRepresentante: "", // string
	  primerAp: "", // string
	  segundoAp: "", // string
	  curp: "", // string
	  rfc: "" // string
	},
	AperturaCuentaContacto: {
	  idAperturaCuentaContacto: 0, // integer ($int32)
	  idAperturaCuenta: "", // string
	  nombre: "", // string
	  primerAp: "", // string
	  segundoAp: "", // string
	  telefono: "", // string
	  correoElectronico: "", // string
	  nombreUsuario: "" // string
	},
	AperturaCuentaBrokers: {
	  idAperturaCuentaBroker: 0, // integer ($int32)
	  idAperturaCuenta: "", // string
	  idBroker: "" // Nota: Faltaba el tipo de dato en tu especificación, así que lo dejo como string, ajusta según sea necesario
	},
	documentos: [
		{
			idAperturaCuentaDocumento : 0,
			idAperturaCuenta: "",
			idDocumento : 0,
			nombreDocumento : "",
			docBase64 : "",
			observaciones: "",
			fechaCreacion : "",
			fechaRevision: "",
			idUsuario : "",
			idEstatus: 1,
		}
	]
  };