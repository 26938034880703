import React, { useEffect, useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';

const validateCURP = (curp) => {
    const regex = /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;
    return regex.test(curp);
};

const validateRFC = (rfc) => {
    const regex = /^[A-Z]{4}\d{6}[A-Z0-9]{3}$/;
    return regex.test(rfc);
};

const LegalRepresentativeForm = ({ formData, onFormDataChange }) => {
    const [errors, setErrors] = useState({});

    const [isFirstTimeLoaded, setFirsTimeLoaded] = useState(1)
    useEffect(() => {
        isFirstTimeLoaded === 1 ? setFirsTimeLoaded(2) : validateForm();
        // validateForm();
    }, [formData]);

    const validateForm = () => {
        const newErrors = {};
        let valid = true;

        if (!formData.legalRepName.trim()) {
            newErrors.legalRepName = 'El nombre del representante legal es requerido';
            valid = false;
        }
        if (!formData.legalRepLastName.trim()) {
            newErrors.legalRepLastName = 'El apellido paterno del representante legal es requerido';
            valid = false;
        }
        if (!formData.CURP.trim()) {
            newErrors.CURP = 'El CURP es requerido';
            valid = false;
        } else if (!validateCURP(formData.CURP)) {
            newErrors.CURP = 'CURP inválido, por favor verifique.';
            valid = false;
        }
        if (!formData.RFC.trim()) {
            newErrors.RFC = 'El RFC es requerido';
            valid = false;
        } else if (!validateRFC(formData.RFC)) {
            newErrors.RFC = 'RFC inválido, por favor verifique.';
            valid = false;
        }

        setErrors(newErrors);
        onFormDataChange(formData, valid);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        const isRFC = name === 'RFC'
        const isCurp = name === 'CURP'

        onFormDataChange({
            ...formData,
            [name]: isRFC || isCurp ? value.toUpperCase() : value
        }, false);
    };

    return (
        <Form noValidate>
            <Row>
                <Col className='my-3' md={12}>
                    <Form.Group controlId="legalRepName">
                        <Form.Label className="ralewayRegular">Nombre del representante legal <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="legalRepName"
                            value={formData.legalRepName}
                            onChange={handleChange}
                            isInvalid={!!errors.legalRepName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.legalRepName}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={12}>
                    <Form.Group controlId="legalRepLastName">
                        <Form.Label className="ralewayRegular">Primer apellido del representante <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="legalRepLastName"
                            value={formData.legalRepLastName}
                            onChange={handleChange}
                            isInvalid={!!errors.legalRepLastName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.legalRepLastName}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={12}>
                    <Form.Group controlId="legalRepSecondLastName">
                        <Form.Label className="ralewayRegular">Segundo apellido representante</Form.Label>
                        <Form.Control
                            type="text"
                            name="legalRepSecondLastName"
                            value={formData.legalRepSecondLastName}
                            onChange={handleChange}
                            isInvalid={!!errors.legalRepSecondLastName}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.legalRepSecondLastName}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>

            <Row>
                <Col className='my-3' md={12}>
                    <Form.Group controlId="CURP">
                        <Form.Label className="ralewayRegular">CURP <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="CURP"
                            value={formData.CURP}
                            onChange={handleChange}
                            isInvalid={!!errors.CURP}
                            maxLength={18} 
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.CURP}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
                <Col className='my-3' md={12}>
                    <Form.Group controlId="RFC">
                        <Form.Label className="ralewayRegular">RFC <span className="text-danger">*</span></Form.Label>
                        <Form.Control
                            type="text"
                            name="RFC"
                            value={formData.RFC}
                            onChange={handleChange}
                            isInvalid={!!errors.RFC}
                            maxLength={13} // Limita el input a 12 caracteres
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.RFC}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Col>
            </Row>
        </Form>
    );
};

export default LegalRepresentativeForm;
