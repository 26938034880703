import 'bootstrap/dist/css/bootstrap.css';
import ButtonNewEnterprise from './button-new-enterprise';
import React, { useState } from 'react';
import { Form, Col, Button, Modal } from 'react-bootstrap';

function FormContact() {
	const [envioExitoso, setEnvioExitoso] = useState(false);
	const [formularioValues, setFormularioValues] = useState({
		nombre: '',
		apellidos: '',
		telefono: '',
		email: '',
		empresa: '',
		mensaje: '',
	});

	const handleEnvioFormulario = (event) => {

		setEnvioExitoso(true);

		setFormularioValues({
			nombre: '',
			apellidos: '',
			telefono: '',
			email: '',
			empresa: '',
			mensaje: '',
		});

		event.preventDefault();
	};

	const handleCloseModal = () => {
		setEnvioExitoso(false);
	};

	const handleInputChange = (event) => {
		const { name, value } = event.target;
		setFormularioValues({
			...formularioValues,
			[name]: value,
		});
	};
	return (

		<Form className="row  g-3" onSubmit={handleEnvioFormulario}>
			<Col md={6}>
				<Form.Label htmlFor="nombre" className="form-label ralewayRegular">
					Nombre(s) <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					type="text"
					id="nombre"
					name="nombre"
					value={formularioValues.nombre}
					onChange={handleInputChange}
					required
				/>
			</Col>
			<Col md={6}>
				<Form.Label htmlFor="apellidos" className="form-label ralewayRegular">
					Apellidos <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					type="text"
					id="apellidos"
					name="apellidos"
					value={formularioValues.apellidos}
					onChange={handleInputChange}
					required
				/>
			</Col>
			<Col md={6}>
				<Form.Label htmlFor="telefono" className="form-label ralewayRegular">
					Teléfono <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					type="text"
					id="telefono"
					name="telefono"
					value={formularioValues.telefono}
					onChange={handleInputChange}
					required
				/>
			</Col>
			<Col md={6}>
				<Form.Label htmlFor="email" className="form-label ralewayRegular">
					Email <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					type="email"
					id="email"
					name="email"
					placeholder="ejemplo@hotmail.com"
					value={formularioValues.email}
					onChange={handleInputChange}
					required
				/>
			</Col>
			<Col md={12}>
				<Form.Label htmlFor="empresa" className="form-label ralewayRegular">
					Empresa <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					type="text"
					id="empresa"
					name="empresa"
					value={formularioValues.empresa}
					onChange={handleInputChange}
					required
				/>
			</Col>
			<Col md={12}>
				<Form.Label htmlFor="validationTextarea" className="form-label ralewayRegular">
					Mensaje <span className="text-danger">*</span>
				</Form.Label>
				<Form.Control
					as="textarea"
					id="validationTextarea"
					name="mensaje"
					value={formularioValues.mensaje}
					onChange={handleInputChange}
					rows={3}
					required
				/>
			</Col>
			<Col md={12} className='mb-5'>
				<button type="submit" className="button-mg-medium ">
					Enviar mensaje
				</button>
			</Col>
			<Modal show={envioExitoso} onHide={handleCloseModal}>
				<Modal.Header closeButton>
					<Modal.Title>Datos enviados</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<p>Informacion de contacto mandada correctamente</p>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="primary" onClick={handleCloseModal}>
						Cerrar
					</Button>
				</Modal.Footer>
			</Modal>
		</Form>


	);
}

export default FormContact;