import axios from 'axios';
import { API_BASE_URL, API_BASE_TEST, BASE_URL, BASE_URL_TEST } from '../../helpers/GlobalVariables';

export const ApiPostApertureClient = (dataClient = {}, wantGroup = false, isDev = false) => {
	const urlBase = isDev ? BASE_URL_TEST : BASE_URL;

	const queryStringParams = {
		AperturaCuentaEmpresa: {
			idAperturaCuenta: dataClient.AperturaCuentaEmpresa.idAperturaCuenta,
			nombreEmpresa: dataClient.AperturaCuentaEmpresa.nombreEmpresa,
			razonSocial: dataClient.AperturaCuentaEmpresa.razonSocial,
			rfcEmpresa: dataClient.AperturaCuentaEmpresa.rfcEmpresa,
			descripcion: dataClient.AperturaCuentaEmpresa.descripcion === "" ? null : dataClient.AperturaCuentaEmpresa.descripcion,
			calle: dataClient.AperturaCuentaEmpresa.calle,
			ciudad: dataClient.AperturaCuentaEmpresa.ciudad,
			codPostal: dataClient.AperturaCuentaEmpresa.codPostal,
			colonia: dataClient.AperturaCuentaEmpresa.colonia,
			estado: dataClient.AperturaCuentaEmpresa.estado,
			numExterior: dataClient.AperturaCuentaEmpresa.numExterior,
			numInterior: dataClient.AperturaCuentaEmpresa.numInterior,
			pais: dataClient.AperturaCuentaEmpresa.pais,
			tiempoResidenciaAnios: dataClient.AperturaCuentaEmpresa.tiempoResidenciaAnios,
			esGrupoEmpresarial: dataClient.AperturaCuentaEmpresa.esGrupoEmpresarial,
			rutaDocumentos: dataClient.AperturaCuentaEmpresa.rutaDocumentos,
			idEstatus: dataClient.AperturaCuentaEmpresa.idEstatus,
			fechaApertura: dataClient.AperturaCuentaEmpresa.fechaApertura,
			claveGrupoEmpresarial: dataClient.AperturaCuentaEmpresa.claveGrupoEmpresarial,
			fechaModificacion: dataClient.AperturaCuentaEmpresa.fechaModificacion
		},
		AperturaCuentaRepresentante: {
			idAperturaCuentaRepresentante: dataClient.AperturaCuentaRepresentante.idAperturaCuentaRepresentante,
			idAperturaCuenta: dataClient.AperturaCuentaRepresentante.idAperturaCuenta,
			nombreRepresentante: dataClient.AperturaCuentaRepresentante.nombreRepresentante,
			primerAp: dataClient.AperturaCuentaRepresentante.primerAp,
			segundoAp: dataClient.AperturaCuentaRepresentante.segundoAp === "" ? null : dataClient.AperturaCuentaRepresentante.segundoAp,
			curp: dataClient.AperturaCuentaRepresentante.curp,
			rfc: dataClient.AperturaCuentaRepresentante.rfc
		},
		AperturaCuentaContacto: {
			idAperturaCuentaContacto: dataClient.AperturaCuentaContacto.idAperturaCuentaContacto,
			idAperturaCuenta: dataClient.AperturaCuentaContacto.idAperturaCuenta,
			nombre: dataClient.AperturaCuentaContacto.nombre,
			primerAp: dataClient.AperturaCuentaContacto.primerAp,
			segundoAp: dataClient.AperturaCuentaContacto.segundoAp === "" ? null : dataClient.AperturaCuentaContacto.segundoAp,
			telefono: dataClient.AperturaCuentaContacto.telefono,
			correoElectronico: dataClient.AperturaCuentaContacto.correoElectronico,
			nombreUsuario: dataClient.AperturaCuentaContacto.nombreUsuario
		},
		AperturaCuentaBrokers: {
			idAperturaCuentaBroker: dataClient.AperturaCuentaBrokers.idAperturaCuentaBroker === 0 ? null : dataClient.AperturaCuentaBrokers.idAperturaCuentaBroker,
			idAperturaCuenta: dataClient.AperturaCuentaBrokers.idAperturaCuenta ? dataClient.AperturaCuentaBrokers.idAperturaCuenta : null,
			idBroker: dataClient.AperturaCuentaBrokers.idBroker ? dataClient.AperturaCuentaBrokers.idBroker : null
		}
	};

	const body = dataClient.documentos;

	const url = `${urlBase}/AddAperturaCuenta`;

	const filteredQueryStringParams = Object.keys(queryStringParams).map(key => {
		const filteredSubParams = Object.keys(queryStringParams[key]).filter(subKey => {
			return queryStringParams[key][subKey] !== null;
		}).map(subKey => {
			return `${key}.${subKey}=${encodeURIComponent(String(queryStringParams[key][subKey]))}`;
		}).join('&');
		return filteredSubParams;
	}).filter(param => param !== '');

	const queryString = filteredQueryStringParams.join('&');

	return axios.post(`${url}?${queryString}`, body)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error;
		});
}

export const ApiPostApertureClientWithOutDocuments = (dataClient = {}, wantGroup = false, isDev = false) => {
	const urlBase = isDev ? BASE_URL_TEST : BASE_URL
	if (wantGroup) {
		dataClient["grupoEmpresarial"] = true;
	}
	delete dataClient[""]

	return axios.post(`${urlBase}/AddAperturaCuentaSinDocumentos`, dataClient)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}

export const ApiGetDocumentosAperturaCuenta = (isDev = false, queryParameters = {
	proceso: "Alta de empresas",
	activo: true
}) => {
	const urlBase = isDev ? BASE_URL_TEST : BASE_URL

	return axios.get(`${urlBase}/GetDocumentosAperturaCuenta`, { params: queryParameters })
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}


export const ApiSendEmailForANewMerchantAccount = (queryParameters = {
	idAperturaCuenta: "",
	claveGrupoEmpresarial: ""
}, isDev = false) => {

	const urlBase = isDev ? BASE_URL_TEST : BASE_URL

	// Crear un string de query params a partir del objeto queryParameters
	const queryParamsString = new URLSearchParams(queryParameters).toString();

	// Hacer la solicitud con los query params en la URL
	return axios.post(`${urlBase}/EnviarCorreoAperturaCuenta?${queryParamsString}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}

export const ApiGetDocumentosAperturaCuentaRejected = (isDev = false, queryParameters = {
	idAperturaCuenta: "",
}) => {
	const urlBase = isDev ? BASE_URL_TEST : BASE_URL

	const queryParamsString = new URLSearchParams(queryParameters).toString();

	return axios.get(`${urlBase}/GetDocumentosRechazadosxEmpresa?${queryParamsString}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}

export const ApiGetDocumentosAperturaCuentaMissing = (queryParameters = {
	idAperturaCuenta: "",
}, isDev = false) => {

	const urlBase = isDev ? BASE_URL_TEST : BASE_URL

	const queryParamsString = new URLSearchParams(queryParameters).toString();

	return axios.get(`${urlBase}/GetDocumentosFaltantesxEmpresa?${queryParamsString}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}

export const ApiUpdateDocument = (
	idAperturaCuenta = "",
	isDev = false,
	documentToUpdate = {
		documentos: [{
			idDocumento: 0,
			nombreDocumento: '',
			docBase64: '',
		}]
	}
) => {
	// Crear el query con el nombre y valor correcto
	const queryParams = new URLSearchParams({ idAperturaCuenta }).toString();




	const urlBase = isDev ? BASE_URL_TEST : BASE_URL;
	delete documentToUpdate[""];

	const documentsToUpdate = documentToUpdate.documentos;
	return axios.post(`${urlBase}/UpdActualizaDocumento?${queryParams}`, documentsToUpdate)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}


export const ApiGetAperturaCuentaContinue = (queryParameters = {
	idAperturaCuenta: "",
}, isDev = false) => {
	const urlBase = isDev ? API_BASE_TEST : BASE_URL
	const queryParamsString = new URLSearchParams(queryParameters).toString();
	return axios.get(`${urlBase}/clients/GetAperturaCuentaContinue?${queryParamsString}`)
		.then(response => {
			//(response);
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}

export const ApiGetDatosEmpresaxFolio = (queryParameters = {
	idAperturaCuenta: "",
}, isDev = false) => {
	const urlBase = isDev ? API_BASE_TEST : BASE_URL
	const queryParamsString = new URLSearchParams(queryParameters).toString();
	return axios.get(`${urlBase}/clients/GetDatosEmpresaxFolio?${queryParamsString}`)
		.then(response => {
			return response;
		})
		.catch(error => {
			console.error('Error al hacer la solicitud a la API:', error.message);
			throw error; // Lanzar el error para que sea capturado por el código que llama a esta función
		});
}