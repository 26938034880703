import React from "react";
import Carousel from "./componets/carousel";
import CardLandingMain from "./componets/cards-landing-main";
import CreditCardLandingGeneral from "./componets/credit-card-landing-general-view"
import Section4Landing from './componets/section-4-landing'
import ConcatLanding from "./componets/contact-landing";
import backgroundCircles from './assets/banner_money_giver_fondo2.jpg'
import CarouselComponet from "./componets/carousel";
import CaruselResponsivo from "./componets/carousel-responsive";
import { useLocation } from 'react-router-dom';

class Main extends React.Component {
	constructor(props) {
		super(props)
		this.state = {};
	}


	render() {

		document.title = `Inicio`;
		return (
			<main className="">
				<title>Inicio</title>
				<div className="">
					<div className="">
						<div className="d-flex-row justify-content-evenly align-items-center ">
							<div className="container">
								<CaruselResponsivo />
							</div>
							<section className="text-center verde-gradient-fondo" >
									<div className="row justify-content-md-center">
										<section className=" d-flex flex-column align-items-center justify-content-center fondo-verde">
											<div className="container text-center p-5 m-5">
												<h2 className='ralewayBold fs-2 p-5 text-white'>Simplificamos tu experiencia financiera.<br/>Hacemos que el manejo de tu dinero sea más sencillo para que todo lo encuentres en una misma plataforma.</h2>
											</div>
										</section>

								</div>

							</section>
							<CardLandingMain />
							<CreditCardLandingGeneral />
							<Section4Landing />
							<ConcatLanding />
						</div>
					</div>
				</div>
			</main>
		);
	}
}

export default Main;